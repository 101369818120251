import { Typography } from "@packages/ds";
import * as ModalPrimitive from "@radix-ui/react-dialog";
import styled from "styled-components";

import { mediumShadow } from "ds/Shadows";

const OFFSET_TOP = 80;
const MIN_MARGIN_BOTTOM = 16;

export const Content = styled(ModalPrimitive.Content)<{
  width: number | string;
  height?: string;
}>`
  width: ${({ width }) => (typeof width === "string" ? width : `${width}px`)};
  ${({ height }) => `height: ${height || "initial"};`}
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: ${OFFSET_TOP}px;
  max-height: calc(100% - ${OFFSET_TOP + MIN_MARGIN_BOTTOM}px);
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--modal-snow-background-color, var(--snow));
  ${mediumShadow}
  border-radius: 4px;
  padding: 24px 32px 32px;
  z-index: 999;

  ${({ width }) =>
    typeof width === "number" &&
    `
    @media (max-width: ${width}px) {
      width: 95%;
    }
  `}

  ${Typography.Title} {
    margin: 24px 0 16px 0;
  }
`;

export default Content;
