import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import styled from "styled-components";

export type { TooltipArrowProps as ArrowProps } from "@radix-ui/react-tooltip";

export const Arrow = styled(TooltipPrimitive.Arrow)`
  all: unset;
  fill: var(--mode-overrides-tooltip-bg);
  width: 16px;
  box-sizing: border-box;
  height: 7px;
  margin: 0 10px 1px 10px;
  stroke: var(--mode-overrides-tooltip-bg);
  stroke-width: 3;
  stroke-linejoin: round;
`;
