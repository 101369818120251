import { CriticalIcon, InfoIcon, SuccessIcon, NormalIcon } from "@packages/ds";
import styled from "styled-components";

export const Root = styled.div`
  flex: 0 0 24px;
  height: 24px;
`;

export const Normal = styled(NormalIcon)`
  fill: var(--icon-gold-fill, var(--gold));
`;

export const Success = styled(SuccessIcon)`
  fill: var(--theme-success-600);
`;

export const Information = styled(InfoIcon)`
  fill: var(--theme-graph-900);
`;

export const Critical = styled(CriticalIcon)`
  fill: var(--icon-red-fill, var(--red));
`;
