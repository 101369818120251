import React from "react";

import { ChevronRightIcon } from "@packages/ds";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import HeaderMenuMobile from "Containers/NavBar/HeaderMenu/mobile";
import HelpButton from "Containers/NavBar/HelpMenu/mobile/HelpButton";
import OrganizationMobileMenuFeatures from "Containers/NavBar/OrganizationsSwitcher/OrganizationMenu/mobile/OrganizationMobileMenuFeatures";
import UserInfo from "Containers/NavBar/UserMenu/desktop/UserInfo";
import { IconControl } from "ds/Button";
import useUrls from "Hooks/useUrls";
import { getFeatureFlag } from "Reducers/featureFlags";
import { selectFeatureCreateProjectEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { useAppSelector } from "Store/hooks";

import {
  CustomMenuLink,
  FloatingContainer,
  ThemeText,
  UserMenu
} from "../styles";

import * as S from "./styles";

import type { Me, AuthUser, AccountsProfile } from "@packages/client";

interface Props {
  user: Me;
  profile?: AccountsProfile & AuthUser;
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
  setIsUserMobileMenu: (value: boolean) => void;
  setIsHelpMobileMenu: (value: boolean) => void;
  setIsOrganizationSelectionMenu: (value: boolean) => void;
  isInviteModalOpen: boolean;
  setIsInviteModalOpen: (value: boolean) => void;
}

const MainMobileMenuView = ({
  user,
  profile,
  isOpen,
  setIsOpen,
  setIsUserMobileMenu,
  setIsHelpMobileMenu,
  setIsOrganizationSelectionMenu,
  isInviteModalOpen,
  setIsInviteModalOpen
}: Props) => {
  const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");
  const formatMessage = useIntl().formatMessage;

  const createProjectEnabled = useAppSelector(
    selectFeatureCreateProjectEnabled
  );
  const { organizationId } = useParams<{ organizationId: string }>();

  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const [logoutUrl] = useUrls("root.logout");

  return (
    <FloatingContainer>
      <>
        <HeaderMenuMobile
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          backButton={false}
        />

        <UserMenu>
          <>
            <ThemeText>{formatMessage({ id: "organizations" })}</ThemeText>
            <S.SelectOrganizationsContainer
              onClick={() => setIsOrganizationSelectionMenu(true)}
            >
              <S.SelectOrganizationText>
                {formatMessage({ id: "select_organization" })}
              </S.SelectOrganizationText>

              <IconControl>
                <ChevronRightIcon />
              </IconControl>
            </S.SelectOrganizationsContainer>

            <S.OrganizationContainer>
              <OrganizationMobileMenuFeatures
                onOpenChange={() => setIsOpen(false)}
                isInviteModalOpen={isInviteModalOpen}
                setIsInviteModalOpen={setIsInviteModalOpen}
              />
            </S.OrganizationContainer>

            {createProjectEnabled && organization?.vendor !== "upsun" && (
              <S.CreateProjectContainer>
                <S.CustomAddButton
                  analyticId="create_project"
                  id="create_project"
                  to={`/${organizationId ?? "-"}/create-project`}
                >
                  {formatMessage({ id: "create_project" })}
                </S.CustomAddButton>
              </S.CreateProjectContainer>
            )}

            {organizationEnabled && (
              <S.UserSettingsContainer
                onClick={() => setIsUserMobileMenu(true)}
              >
                <div>
                  <ThemeText>
                    {formatMessage({ id: "user_settings" })}
                  </ThemeText>
                  <UserInfo
                    displayName={profile?.display_name || user.display_name}
                    picture={profile?.picture || user.picture}
                    email={profile?.email || user.email}
                  />
                </div>
                <IconControl style={{ paddingTop: 35 }}>
                  <ChevronRightIcon />
                </IconControl>
              </S.UserSettingsContainer>
            )}
            <S.Separator />
            <S.HelpContainer onClick={() => setIsHelpMobileMenu(true)}>
              <HelpButton setIsOpen={() => true} isOpen />

              <IconControl>
                <ChevronRightIcon />
              </IconControl>
            </S.HelpContainer>
            <S.Separator />
            <CustomMenuLink url={logoutUrl}>
              {formatMessage({ id: "menu.logout" })}
            </CustomMenuLink>
          </>
        </UserMenu>
      </>
    </FloatingContainer>
  );
};

export default MainMobileMenuView;
