import { Typography, LabelProps } from "@packages/ds";
import styled from "styled-components";

export interface TextProps extends LabelProps {
  disabled?: boolean;
  isValid?: boolean;
}

export const Text = styled(Typography.Label)<TextProps>`
  ${({ isValid = true, disabled }) =>
    !isValid
      ? "color:var(--mode-text-critical-default)"
      : disabled
        ? "color:var(--mode-text-neutral-weakest-disabled)"
        : "color:var(--mode-text-neutral-default-weak)"};
`;

export default Text;
