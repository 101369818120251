import { Typography } from "@packages/ds";
import styled from "styled-components";

import { Link as LinkDs } from "ds/Button";
import Checkbox from "ds/Checkbox";
import * as CodeDs from "ds/Code";

export const Code = styled(Typography.Code)`
  display: flex;
  align-items: center;
  height: 24px;
  margin: auto 0 0 0;

  & > ${Typography.Label} {
    color: var(--section-color-primary-600-color, var(--color-primary-600));
  }
`;

export const BottomWrapper = styled.div<{
  justifyContent: "space-between" | "end";
}>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-top: 15px;
  margin-bottom: 24px;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const StickerWrapper = styled.div`
  margin: 24px 0 34px 0;
`;

export const BodyText = styled(Typography.BodyText)`
  margin-top: 16px;
`;

export const LiveBackupText = styled(Typography.BodyText)`
  margin-right: 12px;
`;
export const CodeBlock = styled(CodeDs.Block)`
  margin-bottom: 16px;
`;

export const ViewPricingLink = styled(LinkDs)`
  font-weight: 600;
  display: inline;
  font-size: 13px;
`;
