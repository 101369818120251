import React, { ComponentProps } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import ProjectMoreLinks from "Components/ProjectMoreLinks";
import useMediaQuery from "Hooks/useMediaQuery";
import { toggleProjectWizard, setForceOpen } from "Reducers/project/wizard";
import { setProjectContext } from "Reducers/settingsMenu";
import { useAppDispatch } from "Store/hooks";

import FloatBox from "../FloatBox";
import NavigationMenu from "../NavigationMenu";

import {
  LiveButton,
  SettingsIcon,
  SettingsIconContainer,
  NavigationRightItemsContainer
} from "./Project.styles";

import type { Subscription } from "@packages/client";

interface Props extends ComponentProps<typeof NavigationMenu> {
  subscription?: Subscription;
  projectId: string;
}

const ProjectNavBar = ({
  subscription,
  project,
  organizationId,
  projectId,
  ...props
}: Props) => {
  const onDesktop = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const settingsLink = `/${organizationId}/${projectId}/-/settings`;

  const handleSettingsClicked = e => {
    e.stopPropagation();
    dispatch(setProjectContext());
    navigate(settingsLink);
  };

  return (
    <FloatBox>
      <NavigationMenu
        project={project}
        organizationId={organizationId}
        {...props}
      />
      {onDesktop && (
        <NavigationRightItemsContainer>
          {subscription && (
            <LiveButton
              project={project}
              subscription={subscription}
              organizationId={organizationId}
            />
          )}
          <SettingsIconContainer id="settings" onClick={handleSettingsClicked}>
            <SettingsIcon />
          </SettingsIconContainer>
          {project && (
            <ProjectMoreLinks
              from="project"
              project={project}
              subscription={subscription}
              toggleProjectWizard={() => {
                if (
                  pathname.includes(`/${organizationId}/${projectId}/settings`)
                ) {
                  dispatch(setForceOpen(true));
                  navigate(`/${organizationId}/${projectId}`);
                } else {
                  dispatch(
                    toggleProjectWizard({
                      organizationId,
                      projectId
                    })
                  );
                }
              }}
              isNav={true}
            />
          )}
        </NavigationRightItemsContainer>
      )}
    </FloatBox>
  );
};

export default ProjectNavBar;
