import { useEffect, useMemo } from "react";

import { ExtendedAccessElementWithMetadata } from "Api/users/getUserExtendedAccess";
import { meSelector } from "Reducers/app/selectors";
import { selectFeatureOrganizationsEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import {
  extendedProjectAccessSelector,
  loadExtendedAccess
} from "Reducers/project/project";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import type { Project } from "@packages/client";

export type CommonProject = {
  id: string;
  organizationId: string;
  organizationName: string;
  region: string;
  subscriptionId: string;
  title: string;
};

function extendedAccessProjectToCommonProject(
  project: ExtendedAccessElementWithMetadata
): CommonProject {
  return {
    id: project.resource_id || "",
    organizationId: project.organization_id || "",
    organizationName: project.organization_name || "",
    region: project.project_region || "",
    subscriptionId: project.project_subscription_id || "",
    title: project.project_title as string
  };
}

export function meProjectToCommonProject(
  project: Project,
  userName: string
): CommonProject {
  return {
    id: project.id || "",
    organizationId: project.organization_id || "",
    organizationName: userName,
    region: project.region,
    subscriptionId: project.subscription_id,
    title: project.title || ""
  };
}

export const useExtendedProjectAccess = () => {
  const dispatch = useAppDispatch();
  const me = useAppSelector(meSelector);
  const projects = useAppSelector(extendedProjectAccessSelector);

  const isOrganizationsEnabled = useAppSelector(
    selectFeatureOrganizationsEnabled
  );

  const userId = me?.id;

  useEffect(() => {
    if (userId && isOrganizationsEnabled)
      dispatch(loadExtendedAccess({ userId }));
  }, [dispatch, userId, isOrganizationsEnabled]);

  const commonProjects = useMemo(() => {
    if (isOrganizationsEnabled)
      return projects.map(extendedAccessProjectToCommonProject);

    return (
      me?.projects.map(project =>
        meProjectToCommonProject(project, me.username)
      ) || []
    );
  }, [projects, me, isOrganizationsEnabled]);

  return commonProjects;
};
