import React, { useMemo } from "react";

import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import config from "console_config";
import { Link } from "ds/Button";
import {
  checkGoLive,
  getSubscriptionEditUrl,
  splitStringByChar
} from "Libs/utils";
import {
  selectFeatureBillingEnabled,
  selectExternalUrlEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { RootState } from "Store/configureStore";
import { useAppSelector } from "Store/hooks";

import * as S from "./LiveButton.styles";

import type { Project, Subscription } from "@packages/client";

export interface LiveButtonProps {
  project: Project;
  subscription: Subscription;
  organizationId: string;
}

const LiveButton = ({
  organizationId,
  project,
  subscription
}: LiveButtonProps) => {
  const intl = useIntl();
  const location = useLocation();

  const billingEnabled = useAppSelector(selectFeatureBillingEnabled);
  const externalUrlEnabled = useAppSelector(selectExternalUrlEnabled);

  const organization = useSelector((state: RootState) =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription, organization }),
    [project, subscription, organization]
  );

  const getButton = () => {
    if (!project.subscription?.plan && !project.subscription?.resources) {
      return false;
    }

    const { pathname } = splitStringByChar(subscriptionEditUrl?.url || "", "#");

    const internalProps = {
      to: pathname,
      state: { from: "project", url: location.pathname }
    };
    const externalProps = {
      href: subscriptionEditUrl?.url || "#"
    };
    const linkProps = externalUrlEnabled ? externalProps : internalProps;

    const goLive = checkGoLive(project, organization);

    switch (goLive) {
      case "live":
        return (
          <S.Live>
            {intl.formatMessage({
              id: "project.state.live",
              defaultMessage: "Live"
            })}
          </S.Live>
        );
      case "development":
        if (!subscriptionEditUrl) return;
        return (
          <Link variant="primary" {...linkProps}>
            {intl.formatMessage({
              id: "project.screenshot.state.not_live",
              defaultMessage: "Go live"
            })}
          </Link>
        );
      case "no-permission":
        return false;
      default:
        return (
          <Link
            variant="primary"
            to={`/${organizationId}/${project.id}/-/settings/domains`}
          >
            {intl.formatMessage({
              id: "project.state.not_live",
              defaultMessage: "Go live"
            })}
          </Link>
        );
    }
  };

  const disableGoLive =
    !billingEnabled && !config.CUSTOM_URL_SUBSCRIPTION_UPGRADE;

  return disableGoLive ? null : <S.Wrapper>{getButton()}</S.Wrapper>;
};

export default LiveButton;
