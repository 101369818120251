import { Typography } from "@packages/ds";
import styled from "styled-components";

import { ButtonWrapper as BaseButtonWrapper, Link } from "ds/Button";
import LabelDs from "ds/Label";
import * as Sticker from "ds/Sticker";

export const SubHeading = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

export const OperationDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  .link {
    text-decoration: none;
    color: var(--section-color-primary-600-color, var(--color-primary-600));
  }
  .operation-name {
    font-weight: 700;
    margin: 0 4px;
  }
`;
export const EnvironmentName = styled.b`
  margin-left: 4px;
`;
export const ApplicationList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  li:last-child {
    .app-divider {
      display: none;
    }
  }
`;

export const ApplicationCommandWrapper = styled.div`
  display: flex;
  padding: 4px 0 5px 0;
  justify-content: space-between;
  ${SubHeading} {
    line-height: 0;
  }
  .code-command-control {
    gap: 16px;
    display: flex;
    align-items: center;
  }
`;

export const SectionDivider = styled.hr`
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 0;
  margin-top: 0;
  background-color: ${"var(--divider-periwinkle-grey-background-color,var(--periwinkle-grey))"};
`;

export const AppDivider = styled.hr`
  margin: 0;
  background-color: var(--divider-stone-background-color, var(--stone));
`;

export const ApplicationHeaderDivider = styled.hr`
  margin-bottom: 0;
  background-color: var(--section-stone-background-color, var(--stone));
`;

export const ButtonWrapper = styled(BaseButtonWrapper)`
  bottom: 0;
  position: sticky;
  margin-left: -32px;
  background-color: var(--section-snow-background-color, var(--snow));
  margin-right: -32px;
  padding: 32px 32px 0px 32px;
`;

export const SourceOpsCommandWrapper = styled.div<{ expanded: boolean }>`
  margin: 0;
  .three-line {
    .copy-content-wrapper {
      border-radius: 2px !important;
      overflow: scroll;
      height: ${({ expanded }) => (expanded ? "auto" : 0)} !important;
      pre {
        margin-bottom: 16px !important;
        margin: 0;
        padding-bottom: 12px;
        padding-top: 12px;
        height: fit-content;
      }
    }
  }
  button.copy-to-clipboard {
    display: none;
  }
  background: none;
  border: none;
`;

export const ToggleCommandViewButton = styled.span`
  color: var(--section-color-primary-600-color, var(--color-primary-600));
  cursor: pointer;
  font-weight: 600;
  line-height: 16px;
  font-size: 14px;
  font-smooth: antialiazed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const OperationDropdownContainer = styled.div`
  margin-bottom: 24px;
  margin-top: 22px;
`;

export const Applications = styled(SubHeading)`
  margin: 0;
  margin-top: 24px;
`;

export const SourceOperationContainer = styled.div`
  h3 {
    margin-top: 0;
  }

  p {
    line-height: 24px;
  }
  p.operation-notice {
    padding: unset;
    font-size: 15px;
    color: var(--section-ebony-color, var(--ebony));
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 34px;
  padding-bottom: 34px;
`;

export const Pre = styled.pre`
  margin-top: 0;
`;
export const SelectWrapper = styled.div`
  width: 264px;
`;
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;
export const Label = styled(LabelDs)`
  margin-bottom: 0;
`;

export const RuntimeOptionsDescription = styled(Typography.BodyText)`
  padding-top: 20px;
  padding-bottom: 23px;
`;

export const StickerText = styled(Sticker.Text)`
  display: inline;
`;

export const ModalProButtonWrapper = styled(ButtonWrapper)`
  justify-content: space-between;
  padding: 0px 32px;
  margin-top: 30px;
`;
export const ModalButtonWrapper = styled(ButtonWrapper)`
  justify-content: end;
  padding: 0px 32px;
  margin-top: 30px;
`;

export const DocumentationInfo = styled(OperationDescription)`
  font-size: 15px;
  margin-top: 10px !important;
  color: var(--section-ebony-color, var(--ebony));
`;

export const RedirectLink = styled(Link)`
  padding-left: 0px !important;
  font-size: 13px;
  padding-right: 3px;
  height: 16px;
`;

export const StickerRoot = styled(Sticker.Root)`
  padding: 10px 11px;
`;

export const DocumentationLink = styled(Link)`
  margin: 0px !important;
  padding: 0px !important;
`;

export const DocLink = styled(Link)`
  margin-left: 3px;
`;

export const SourceButtonWrapper = styled(BaseButtonWrapper)`
  justify-content: space-between;
  margin-top: 30px;
`;
