import React from "react";

import { Typography, VoucherIcon } from "@packages/ds";
import moment from "moment-timezone";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button, ButtonWrapper } from "ds/Button";
import Modal from "ds/Modal";
import { currentTestTrialSelector } from "Reducers/testMode";

import * as S from "./ModalWelcome.styles";

type ModalWelcomeProps = {
  closeModal: () => void;
  redeemHandler: () => void;
  currentTime: object;
  isOpen: boolean;
  organizationProfile: {
    current_trial: {
      expiration: string;
      spend: {
        formatted: number | string;
      };
    };
  };
};

const ModalWelcome = ({
  closeModal,
  redeemHandler,
  currentTime,
  isOpen,
  organizationProfile
}: ModalWelcomeProps) => {
  const intl = useIntl();
  const currentTestTrial = useSelector(currentTestTrialSelector);
  const current_trial = currentTestTrial || organizationProfile?.current_trial;
  if (!current_trial) return null;
  const { expiration } = current_trial;

  // formatted string of amount that will display in modal
  const amount =
    organizationProfile?.current_trial?.spend?.formatted ||
    currentTestTrial?.spend?.amount ||
    "$30";

  const expirationDate = moment(expiration);

  // Add 1 day to get total duration, inclusive of start date
  const duration = expirationDate.diff(currentTime, "days") + 1;

  return (
    <Modal isOpen={isOpen} size="small" onOpenChange={closeModal}>
      <Typography.Title tag="h3">
        {intl.formatMessage({
          id: "trial.modal.welcome.title"
        })}
      </Typography.Title>

      <Typography.BodyText size="medium" weight="regular">
        {intl.formatMessage(
          {
            id: "trial.modal.welcome.text"
          },
          {
            amount,
            duration
          }
        )}
      </Typography.BodyText>
      <S.Voucher>
        <VoucherIcon fill="var(--icon-ebony-light-fill,var(--ebony-light))" />
        {intl.formatMessage<React.ReactNode>(
          { id: "trial.modal.welcome.voucher" },
          {
            b: chunks => <strong>{chunks}</strong>,
            link: chunks => (
              <Button variant="link" onClick={redeemHandler}>
                {chunks}
              </Button>
            )
          }
        )}
      </S.Voucher>

      <ButtonWrapper spacing="modal" justifyContent="end">
        <Button id="welcome-modal-button" onClick={closeModal}>
          {intl.formatMessage({ id: "okay" })}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default ModalWelcome;
