import { createSelector, createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";
import { gitProjectSelector } from "Reducers/project/project";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

import type { Capabilities } from "@packages/client";
import type { RootState } from "Store/configureStore";

export const loadProjectCapability = createAppAsyncThunk(
  "app/project/capability/load",
  async (
    {
      organizationId,
      projectId
    }: { organizationId: string; projectId: string },
    { getState }
  ) => {
    const project = gitProjectSelector(getState(), {
      organizationId,
      projectId
    });
    return project?.getCapabilities();
  }
);

type CapabilitiesState = {
  data?: Record<string, Record<string, Capabilities | undefined> | undefined>;
  inflight?: Record<string, Record<string, boolean> | undefined>;
};

const initialState: CapabilitiesState = {};

const projectCapabilities = createSlice({
  name: "capabilities",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadProjectCapability.pending, (state, { meta }) => {
        const { projectId, organizationId } = meta.arg;
        setDeep(state, ["inflight", organizationId, projectId], true);
      })
      .addCase(loadProjectCapability.fulfilled, (state, { payload, meta }) => {
        const { projectId, organizationId } = meta.arg;
        setDeep(state, ["inflight", organizationId, projectId], false);
        setDeep(state, ["data", organizationId, projectId], payload);
      })
      .addCase(loadProjectCapability.rejected, (state, { meta }) => {
        const { projectId, organizationId } = meta.arg;
        setDeep(state, ["inflight", organizationId, projectId], false);
      });
  }
});

export const projectCapabilitySelector = (
  state: RootState,
  { organizationId, projectId }: { organizationId: string; projectId: string }
) => state.capabilities?.data?.[organizationId]?.[projectId];

export const selectProjectBuildResourcesEnabled = createSelector(
  projectCapabilitySelector,
  capabilities => capabilities?.build_resources?.enabled
);

export const selectCapabilitiesAreLoading = (
  state: RootState,
  { organizationId, projectId }: { organizationId: string; projectId: string }
) => state.capabilities?.inflight?.[organizationId]?.[projectId];

export const selectEnableCustomBackupSchedules = createSelector(
  projectCapabilitySelector,
  capabilities => capabilities?.data_retention?.enabled
);

export default projectCapabilities.reducer;
