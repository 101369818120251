import React, { useState } from "react";

import { MoreIcon } from "@packages/ds";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import * as Dropdown from "ds/Dropdown";
import { Tooltip } from "ds/Tooltip";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import getUrl from "Libs/getUrl";
import { selectFeatureSourceOperationsEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import { selectProjectBuildResourcesEnabled } from "Reducers/project/capabilities";
import { useAppSelector } from "Store/hooks";

import GoLiveButton from "../GoLiveButton";

import * as S from "./MoreEnvironmentAction.style";

import type { Environment } from "@packages/client";

export enum MORE_ACTION_ID {
  BACKUP = "backup",
  BRANCH = "branch",
  MERGE = "merge",
  REDEPLOY = "redeploy",
  RESUME = "resume",
  SOURCE_OPS = "source_operations",
  SYNC = "sync",
  DELETE_ENVIRONMENT = "delete_environment"
}

interface Props {
  environment: Environment;
  onClick: (arg: {
    id: MORE_ACTION_ID;
    isRuntime?: boolean;
    event: React.FormEvent<HTMLDivElement> | Event;
  }) => void;
}

const MoreEnvironmentAction = ({ environment, onClick }: Props) => {
  const { organizationId, projectId, environmentId } = useDecodedParams<{
    organizationId: string;
    projectId: string;
    environmentId: string;
  }>();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const sourceOperationEnabled = useAppSelector(
    selectFeatureSourceOperationsEnabled
  );
  const formatMessage = useIntl().formatMessage;
  const location = useLocation();
  const isEnvPaused = environment?.status === "paused";
  const areBuildResourcesEnabled = useSelectorWithUrlParams(
    selectProjectBuildResourcesEnabled
  );

  const hasRedeployLink = environment.hasLink("#redeploy");

  const resourcesUrl = areBuildResourcesEnabled
    ? getUrl({
        key: "organization.project.environment.configureResources",
        props: {
          organizationId,
          projectId,
          environmentId: environmentId || "",
          location: location.pathname + location.search,
          queryParams: ""
        }
      })
    : "";

  const dropdownCallback = cb => {
    setIsDropdownOpen(false);
    cb();
  };

  const redeployBtn = (
    <Dropdown.Item
      disabled={!hasRedeployLink}
      onSelect={event =>
        dropdownCallback(() => onClick({ id: MORE_ACTION_ID.REDEPLOY, event }))
      }
      id="environment-redeploy-btn"
      analyticId="redeploy"
    >
      {formatMessage({ id: "redeploy" })}
    </Dropdown.Item>
  );

  const redeployComponent = !hasRedeployLink ? (
    <Tooltip
      tooltip={formatMessage({
        id: "environment.not.redeployed"
      })}
    >
      {redeployBtn}
    </Tooltip>
  ) : (
    redeployBtn
  );

  return (
    <Dropdown.Root
      open={isDropdownOpen}
      onOpenChange={() => setIsDropdownOpen(prevState => !prevState)}
    >
      <S.DropdownTrigger data-testid="trigger-environment-more">
        <MoreIcon />
      </S.DropdownTrigger>
      <Dropdown.Portal>
        <Dropdown.Content align="end">
          <S.GoLiveButtonContainer>
            <GoLiveButton />
          </S.GoLiveButtonContainer>

          {!isEnvPaused && redeployComponent}
          {areBuildResourcesEnabled && (
            <Dropdown.Item
              onClick={() => navigate(resourcesUrl)}
              analyticId="project.morelinks.configure_resources"
              id="environment-branch-btn"
            >
              {formatMessage({
                id: "project.morelinks.configure_resources",
                defaultMessage: "Configure resources"
              })}
            </Dropdown.Item>
          )}
          {(!isEnvPaused || areBuildResourcesEnabled) && (
            <S.DropdownSeparator />
          )}

          <Dropdown.Label>{formatMessage({ id: "git" })}</Dropdown.Label>

          <Dropdown.Item
            analyticId="branch"
            onClick={event => {
              dropdownCallback(() =>
                onClick({ id: MORE_ACTION_ID.BRANCH, event })
              );
            }}
            disabled={!environment.hasLink("#branch")}
            id="environment-branch-btn"
          >
            {formatMessage({ id: "branch" })}
          </Dropdown.Item>

          <Dropdown.Item
            analyticId="merge"
            onClick={event =>
              dropdownCallback(() =>
                onClick({ id: MORE_ACTION_ID.MERGE, event })
              )
            }
            disabled={!environment.hasLink("#merge")}
            id="environment-merge-btn"
          >
            {formatMessage({ id: "merge" })}
          </Dropdown.Item>

          <Dropdown.Item
            onClick={event =>
              dropdownCallback(() =>
                onClick({ id: MORE_ACTION_ID.SYNC, event })
              )
            }
            id="environment-sync-btn"
            disabled={!environment.hasLink("#synchronize")}
            analyticId="sync"
          >
            {formatMessage({ id: "sync" })}
          </Dropdown.Item>

          <S.DropdownSeparator />

          <Dropdown.Label>{formatMessage({ id: "operations" })}</Dropdown.Label>

          {sourceOperationEnabled && (
            <>
              <Dropdown.Item
                onClick={event =>
                  dropdownCallback(() =>
                    onClick({ id: MORE_ACTION_ID.SOURCE_OPS, event })
                  )
                }
                analyticId="run_source_link_message"
              >
                {formatMessage({ id: "run_source_link_message" })}
              </Dropdown.Item>

              <Dropdown.Item
                onClick={event =>
                  dropdownCallback(() =>
                    onClick({
                      id: MORE_ACTION_ID.SOURCE_OPS,
                      isRuntime: true,
                      event
                    })
                  )
                }
                analyticId="run_runtime_link_message"
              >
                {formatMessage({ id: "run_runtime_link_message" })}
              </Dropdown.Item>
            </>
          )}

          <S.DropdownSeparator />

          {environment.hasLink("#backup") && (
            <Dropdown.Item
              id="environment-backup-btn"
              onClick={event =>
                dropdownCallback(() =>
                  onClick({ id: MORE_ACTION_ID.BACKUP, event })
                )
              }
              analyticId="backup"
            >
              {formatMessage({ id: "backup" })}
            </Dropdown.Item>
          )}
          {isEnvPaused && environment.hasLink("#resume") && (
            <Dropdown.Item
              id="environment-resume-btn"
              onClick={event =>
                dropdownCallback(() =>
                  onClick({ id: MORE_ACTION_ID.RESUME, event })
                )
              }
              analyticId="resume"
            >
              {formatMessage({
                id: "resume.modal.title",
                defaultMessage: "Resume environment"
              })}
            </Dropdown.Item>
          )}

          <Dropdown.Item
            onClick={() =>
              navigate(
                getUrl({
                  key: "organization.project.environment.settings",
                  props: {
                    organizationId: organizationId || "",
                    projectId: projectId || "",
                    environmentId: environmentId || ""
                  }
                })
              )
            }
            analyticId="environment_settings"
          >
            {formatMessage({
              id: "environment_settings",
              defaultMessage: "Environment settings"
            })}
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

export default MoreEnvironmentAction;
