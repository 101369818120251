import client from "Libs/platform";

import type { APIObject } from "@packages/client";
import type { AppDispatch, RootState } from "Store/configureStore";

export type OpenTicketState = {
  isLoading: boolean;
  ticket?: unknown;
  error?: unknown;
};

const initialState: OpenTicketState = {
  isLoading: false
  // error: obj
  // ticket: obj
};

const defaultLoadFailureError = {
  message: "An error has occurred while opening a ticket"
};

// Actions
const START = "tickets/open/start";
const FAILURE = "tickets/open/failure";
const SUCCESS = "tickets/open/success";
const RESET = "tickets/open/reset";

type StartAction = { type: typeof START };
type FailureAction = { type: typeof FAILURE; payload: unknown };
type SuccessAction = { type: typeof SUCCESS; payload: unknown };
type ResetAction = { type: typeof RESET };

export type OpenTicketAction =
  | StartAction
  | FailureAction
  | SuccessAction
  | ResetAction;

// Action creators
export const loadStart = (): StartAction => ({ type: START });
export const loadFailure = (error?: unknown): FailureAction => ({
  type: FAILURE,
  payload: error
});
export const loadSuccess = (ticket: unknown): SuccessAction => ({
  type: SUCCESS,
  payload: ticket
});
export const reset = (): ResetAction => ({ type: RESET });

// Thunks
export const open = (ticket: APIObject) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loadStart());
    try {
      const response = await client.openTicket(ticket);
      dispatch(loadSuccess(response));
    } catch (error) {
      dispatch(loadFailure(error));
    }
  };
};

export const reducer = (
  state = initialState,
  action?: OpenTicketAction
): OpenTicketState => {
  switch (action?.type) {
    case START:
      return {
        ...state,
        isLoading: true
      };
    case SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticket: action.payload
      };
    case FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload || defaultLoadFailureError
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export const name = "openTicket";

export function selectOpenTicketIsSuccessful(state: RootState) {
  return !!state.openTicket.ticket;
}

export function selectOpenTicketIsLoading(state: RootState) {
  return state.openTicket.isLoading;
}

export function selectOpenTicketError(state: RootState) {
  return (state.openTicket.error as { message: string } | undefined)?.message;
}

export default reducer;
