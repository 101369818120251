import { DiscountIcon as Discount, TickIcon, Typography } from "@packages/ds";
import styled from "styled-components";

export const Disclaimer = styled.p`
  all: unset;
  color: var(--label-granite-color, var(--granite));
  font-size: 13px;
  font-weight: 600;
`;

export const SubscribeWrapper = styled.div`
  margin-top: 15px;
  border: double 1px transparent;
  border-radius: 16px;
  background-image: linear-gradient(
      var(--section-grey-light-background-color, var(--grey-light)),
      var(--section-grey-light-background-color, var(--grey-light))
    ),
    linear-gradient(to bottom, #ff4a11, #ed49f0, #ddf933);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 32px;
  position: relative;
`;

export const SubscribeImg = styled.img`
  position: absolute;
  bottom: -22px;
  right: -33px;
`;

export const GradientTitle = styled(Typography.Title)`
  line-height: 1.2;
  /* Console does not support multiple colors */
  background: linear-gradient(90deg, #806bff 0%, #ed49f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
`;

export const CheckWrapper = styled.div`
  margin: 12px 0 28px 0;
`;

export const CheckRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Tick = styled(TickIcon)`
  path {
    fill: var(--icon-java-fill, var(--java)) !important;
  }
`;

export const SubscribeActions = styled.div`
  display: flex;
  position: relative;
  z-index: 2;

  & > :nth-child(2) {
    padding: 0 22px;
  }
`;

export const LineItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 19px 0 12px 0;
  padding: 0 8px 0 16px;
`;

export const LineItem = styled(Typography.BodyText)`
  display: inline-flex;
  font-size: 13px;
  align-items: center;
`;

export const BodyTextDescription = styled.div<{ width?: string }>`
  ${({ width }) => (width ? `width: ${width}` : "")};
`;

export const DescriptionIconWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
`;

export const DiscountIcon = styled(Discount)`
  min-width: 24px;
`;

export const TermsInfo = styled(Typography.BodyText)`
  color: var(--text-neutral-weakest, #8b9397);
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 13px;
`;
