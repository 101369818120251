import React from "react";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { Arrow } from "./Arrow.styles";
import { Content } from "./Content.styles";
import { Trigger } from "./Trigger";

interface TooltipDefaultProps {
  children: React.ReactNode;
  tooltip: React.ReactNode;
}

type Props = {
  pointerPosition?: `fromLeft=${string}` | `fromRight=${string}`;
  onOpenChange?: (open: boolean) => void;
  // analyticId?: string;
};

export type TooltipProps = TooltipPrimitive.TooltipProps &
  TooltipDefaultProps &
  Pick<
    TooltipPrimitive.TooltipContentProps,
    "side" | "align" | "sideOffset" | "alignOffset"
  > &
  Props;

export const Tooltip = ({
  tooltip,
  children,
  delayDuration,
  side,
  align,
  sideOffset,
  alignOffset,
  pointerPosition,
  onOpenChange,
  ...rootProps
}: TooltipProps) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        delayDuration={delayDuration || 0}
        onOpenChange={onOpenChange}
        {...rootProps}
      >
        <Trigger>{children}</Trigger>
        <TooltipPrimitive.Portal>
          <Content
            side={side}
            align={align}
            sideOffset={sideOffset}
            alignOffset={alignOffset}
            $pointerPosition={pointerPosition}
          >
            {tooltip}
            <Arrow />
          </Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
