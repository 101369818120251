import * as LabelPrimitive from "@radix-ui/react-label";
import { Trigger as TriggerPrimitive } from "@radix-ui/react-tooltip";
import styled from "styled-components";

const StyledTrigger = styled(TriggerPrimitive)`
  overflow: hidden;
`;

export const Root = styled(LabelPrimitive.Root)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  max-height: 38px;

  ${StyledTrigger} {
    display: flex;
  }
`;

export default Root;
