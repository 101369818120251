import React, { useState } from "react";

import config from "console_config";
import Hamburger from "Containers/NavBar/HeaderMenu/mobile/Hamburger";
import HelpMobileMenu from "Containers/NavBar/HelpMenu/mobile";
import OrganizationMobileMenuSelectOrganization from "Containers/NavBar/OrganizationsSwitcher/OrganizationMenu/mobile/OrganizationMobileMenuSelectOrganization";
import UserMobileMenu from "Containers/NavBar/UserMenu/mobile";

import { Layout } from "../styles";

import MainMobileMenuView from "./MainMobileMenuView";
import * as S from "./styles";

import type { Me, AuthUser, AccountsProfile } from "@packages/client";

interface Props {
  user: Me;
  profile?: AccountsProfile & AuthUser;
  accountUrl: string;
}

const MainMobileMenu = ({ user, profile }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUserMobileMenu, setIsUserMobileMenu] = useState(false);
  const [isHelpMobileMenu, setIsHelpMobileMenu] = useState(false);
  const [isOrganizationSelectionMenu, setIsOrganizationSelectionMenu] =
    useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  return (
    <Layout>
      <S.Container>
        <Hamburger onClick={() => setIsOpen(!isOpen)} />
      </S.Container>
      {isOpen && !isUserMobileMenu && !isHelpMobileMenu && (
        <MainMobileMenuView
          user={user}
          profile={profile}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setIsUserMobileMenu={setIsUserMobileMenu}
          setIsHelpMobileMenu={setIsHelpMobileMenu}
          setIsOrganizationSelectionMenu={setIsOrganizationSelectionMenu}
          isInviteModalOpen={isInviteModalOpen}
          setIsInviteModalOpen={setIsInviteModalOpen}
        />
      )}

      {isOpen && isUserMobileMenu && (
        <UserMobileMenu
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          profile={profile}
          user={user}
          accountUrl={config.URL_ACCOUNTS}
          setIsUserMobileMenu={setIsUserMobileMenu}
        />
      )}

      {isOpen && isHelpMobileMenu && (
        <HelpMobileMenu
          user={user}
          accountUrl={config.URL_ACCOUNTS}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setIsHelpMobileMenu={setIsHelpMobileMenu}
        />
      )}

      {isOpen && isOrganizationSelectionMenu && (
        <OrganizationMobileMenuSelectOrganization
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setIsOrganizationSelectionMenu={setIsOrganizationSelectionMenu}
        />
      )}
    </Layout>
  );
};

export default MainMobileMenu;
