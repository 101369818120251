import React, { useMemo, useState } from "react";

import { Typography } from "@packages/ds";
import { useIntl } from "react-intl";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  MethodsList,
  StepsList,
  StepPropType
} from "Containers/PhoneNumberVerificationModal/types";
import { Button, ButtonWrapper, Link } from "ds/Button";
import * as Radio from "ds/Radio";

import * as C from "../Steps.styles";

const Methods = ({
  config,
  goToStep,
  handleChangeConfig,
  onCancel
}: StepPropType) => {
  const intl = useIntl();

  const [method, setMethod] = useState<MethodsList>(config.methods);

  const handleMethodChange = (method: MethodsList) => {
    setMethod(method);
  };

  const handleSubmit = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault;
    handleChangeConfig(StepsList.methods, method);
    goToStep(StepsList.code);
  };

  const handleContinue = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault;
    handleChangeConfig(StepsList.methods, method);
    goToStep(StepsList.phone);
  };

  const hasValidPhoneNumber = useMemo(
    () => isValidPhoneNumber(config.phone),
    [config.phone]
  );

  return (
    <C.Wrapper>
      <Typography.BodyText>
        {intl.formatMessage({ id: "kyc_verification.methods.intro" })}
      </Typography.BodyText>

      <Radio.Group
        defaultValue={config.methods}
        onValueChange={handleMethodChange}
      >
        {Object.keys(MethodsList).map(method => (
          <Radio.Input
            key={`method-${method}`}
            id={method}
            label={intl.formatMessage({
              id: `kyc_verification.methods.${method}.label`
            })}
            optionDetails={intl.formatMessage({
              id: `kyc_verification.methods.${method}.label_details`
            })}
            value={method}
          />
        ))}
      </Radio.Group>

      <C.Help>
        {intl.formatMessage<React.ReactNode>(
          { id: "kyc_verification.methods.help" },
          {
            a: chunk => (
              <Link to="/support" analyticId="kyc_verification.methods.help">
                {chunk}
              </Link>
            )
          }
        )}
      </C.Help>

      <ButtonWrapper justifyContent="end" spacing="modal">
        <Button
          analyticId="cancel"
          variant="secondary"
          type="button"
          aria-label={intl.formatMessage({ id: "cancel" })}
          onClick={onCancel}
        >
          {intl.formatMessage({ id: "cancel" })}
        </Button>

        {hasValidPhoneNumber ? (
          <Button
            analyticId="kyc_verification.methods.submit"
            type="submit"
            aria-label={intl.formatMessage({
              id: "kyc_verification.methods.submit"
            })}
            onClick={handleSubmit}
          >
            {intl.formatMessage({ id: "kyc_verification.methods.submit" })}
          </Button>
        ) : (
          <Button
            type="submit"
            aria-label={intl.formatMessage({
              id: "kyc_verification.methods.continue"
            })}
            onClick={handleContinue}
            analyticId="kyc_verification.methods.continue"
          >
            {intl.formatMessage({ id: "kyc_verification.methods.continue" })}
          </Button>
        )}
      </ButtonWrapper>
    </C.Wrapper>
  );
};

export default Methods;
