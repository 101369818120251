import React, { useState, useEffect } from "react";

import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Error from "Components/Error";
import ListGroup from "Components/ListGroup/ListGroup";
import PageLayout from "Components/PageLayout";
import NavBar from "Containers/NavBar";
import Button from "ds/Button";
import { meSelector, selectCurrentUserIsAdmin } from "Reducers/app/selectors";
import ProjectLayout from "src/organization/pages/project/components/ProjectLayout/ProjectLayout";

import { Layout } from "../Routes.styles";

import * as S from "./Page.styles";

const Page = () => {
  const intl = useIntl();

  const [appUrl, setAppUrl] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [apps, setApps] = useState<{
    routeEnabled: boolean;
    installed: { [key: string]: { name: string; manifest: object } };
  }>({ routeEnabled: true, installed: {} });
  const [error, setError] = useState<string | boolean>();

  const me = useSelector(meSelector);
  const userIsAdmin = useSelector(selectCurrentUserIsAdmin);

  useEffect(() => {
    if (!userIsAdmin) {
      window.location.href = "/";
      return;
    }

    const storedApps = localStorage.getItem("plugin-apps");
    if (storedApps) {
      const appsObject = JSON.parse(storedApps);
      appsObject.routeEnabled = true;
      setApps(appsObject);
      localStorage.setItem("plugin-apps", JSON.stringify(appsObject));
    } else {
      localStorage.setItem("plugin-apps", JSON.stringify(apps));
    }
  }, [apps, userIsAdmin]);

  const installApp = async () => {
    if (!appUrl) return;

    setError(false);
    setLoading(true);

    try {
      const response = await fetch(appUrl);
      if (response.status !== 200) {
        setError(`Can't get the manifest...`);
        setLoading(false);
        return false;
      }
      const manifest = await response.json();
      apps.installed[manifest.id] = manifest;
      localStorage.setItem("plugin-apps", JSON.stringify(apps));
      setAppUrl("");
      setApps(apps);
      setError(false);
    } catch (err) {
      setError(err?.toString());
    }
    setLoading(false);
  };

  const removeApp = (appId: string) => {
    const {
      routeEnabled,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      installed: { [appId]: value, ...keptApps }
    } = apps;

    localStorage.setItem(
      "plugin-apps",
      JSON.stringify({ routeEnabled, installed: keptApps })
    );
    setApps({ routeEnabled, installed: keptApps });
  };

  if (!me) {
    return null;
  }

  return (
    <>
      <NavBar />
      <PageLayout>
        <Layout>
          <S.Title tag="h2">
            {intl.formatMessage({
              id: "app_manager",
              defaultMessage: "App manager"
            })}
          </S.Title>
          <ProjectLayout>
            <ListGroup>
              <S.Wrapper>
                <S.Input
                  id="manifest_url"
                  name="manifest_url"
                  label="Manifest URL"
                  placeholder="App manifest URL"
                  onChange={e => setAppUrl(e.target.value)}
                  value={appUrl}
                  width="100%"
                  required
                />
                {isLoading ? (
                  <span>Loading</span>
                ) : (
                  <Button
                    id="install_app"
                    type="submit"
                    onClick={installApp}
                    disabled={!appUrl}
                    analyticId="install_app"
                  >
                    Install
                  </Button>
                )}
              </S.Wrapper>
              <S.Wrapper>
                {error && <Error>{error}</Error>}
                {Object.keys(apps.installed).map(appId => (
                  <S.AppItem key={appId}>
                    <span>{apps.installed[appId]?.name || appId}</span>
                    <Button
                      onClick={() => removeApp(appId)}
                      analyticId="remove"
                    >
                      Remove
                    </Button>
                  </S.AppItem>
                ))}
              </S.Wrapper>
            </ListGroup>
          </ProjectLayout>
        </Layout>
      </PageLayout>
    </>
  );
};

export default Page;
