import config from "console_config";
import { trackEventHeap as legacyTrackEventHeap } from "Store/analytics/heapTracker";

import { AnalyticsEvent } from "./types";

export const trackEventHeap = (
  eventOrName: AnalyticsEvent | string,
  payload?: Record<string, any>
): void => {
  if (!config.ID_HEAP || !window.heap) return;

  // Handle legacy format (string + payload)
  // this can probably be removed since we are not changing
  // the imports in the codebase, until we migrate the components
  // but let's keep it until migration is complete
  if (typeof eventOrName === "string") {
    return legacyTrackEventHeap(eventOrName, payload ?? {});
  }

  // Handle new format (AnalyticsEvent)
  // Ensure we always pass an object as the event payload
  const eventPayload = eventOrName.payload ?? {};
  return legacyTrackEventHeap(eventOrName.name, eventPayload);
};
