import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import MaxWidthContainer from "Components/MaxWidthContainer";
import config from "console_config";
import {
  BILLING_PATH_MATCH,
  SETTINGS_PATH_MATCH,
  SUPPORT_PATH_MATCH
} from "Constants/constants";
import BannerManager from "Containers/Banner";
import getUrl from "Libs/getUrl";
import { isProjectOwner } from "Libs/utils";
import { meSelector } from "Reducers/app/selectors";
import { loadEnvironments, environmentSelector } from "Reducers/environment";
import { getFeatureFlag } from "Reducers/featureFlags";
import { loadOrganization } from "Reducers/organization";
import { getOrganizationPaymentSource } from "Reducers/organization/paymentSource/thunks/getOrganizationPaymentSource.thunk";
import { loadSubscription as loadOrganizationSubscription } from "Reducers/organization/subscription";
import {
  getIsLoadingListProjectsSelector,
  gitProjectSelector,
  selectProjectsFromAllOrgs
} from "Reducers/project/project";
import { loadSubscription as loadPlainSubscription } from "Reducers/subscription";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import Breadcrumbs from "./Breadcrumbs";
import DunningTag from "./DunningTag";
import EnvironmentNavBar from "./Environment";
import HelpMenu from "./HelpMenu/HelpMenu";
import InsideNavBar from "./InsideNavBar";
import Logo from "./Logo/Logo";
import OrganizationsSwitcher from "./OrganizationsSwitcher";
import ProjectNavBar from "./Project";
import SearchBar from "./SearchBar/SearchBar";
import {
  NavBarLayout,
  NavBarLeftLayout,
  NavBarRightLayout,
  PageHeader,
  OrganizationDivider
} from "./styles";
import Menu from "./UserMenu";

import type { NavigationTabType } from "src/common/components/SubNavBar/types";

let announcements: React.ReactNode = null;
const announcementsEnabled = getFeatureFlag("ENABLE_ANNOUNCEMENTS");

if (announcementsEnabled) {
  const AnnouncementDropdown = React.lazy(
    () => import("Containers/announcements/Dropdown")
  );

  announcements = <AnnouncementDropdown />;
}

const loadSubscription = (
  getFeatureFlag("ENABLE_ORGANIZATION")
    ? loadOrganizationSubscription
    : loadPlainSubscription
) as typeof loadPlainSubscription;

type NavBarProps = {
  environmentId?: string;
  organizationId?: string;
  projectId?: string;
  username?: string;
  insideNavConfig?: NavigationTabType[];
};

const NavBar = ({
  projectId,
  organizationId,
  username,
  environmentId,
  insideNavConfig
}: NavBarProps) => {
  const projects = useAppSelector(selectProjectsFromAllOrgs);
  const isLoadingProjectList = useAppSelector(getIsLoadingListProjectsSelector);
  const project = useAppSelector(state =>
    gitProjectSelector(state, {
      organizationId,
      projectId
    })
  );
  const environment = useAppSelector(state =>
    environmentSelector(state, {
      organizationId: organizationId!,
      projectId: projectId!,
      environmentId
    })
  );
  const subscription = useAppSelector(state =>
    getFeatureFlag("ENABLE_ORGANIZATION")
      ? state?.organizationSubscription?.data?.[organizationId!]?.[
          project?.getSubscriptionId()!
        ]
      : state?.subscription?.data?.[organizationId!]?.[
          project?.getSubscriptionId()!
        ]
  );

  const organizations = useAppSelector(
    state => state.organization.orgByDescriptionField
  );
  const user = useAppSelector(meSelector);
  const testMode = useAppSelector(state => state.testMode);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // If NavBar loads, make sure these are removed.
    const preLoadingContainer = document.getElementById("pre-loading");
    const fallbackContainer = document.getElementById("fallback");

    preLoadingContainer?.parentNode?.removeChild(preLoadingContainer);
    fallbackContainer?.parentNode?.removeChild(fallbackContainer);
  }, []);

  useEffect(() => {
    const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");
    const billingEnabled = getFeatureFlag("ENABLE_BILLING");

    if (
      billingEnabled &&
      organizationEnabled &&
      organizations[organizationId!]?.name
    ) {
      dispatch(
        getOrganizationPaymentSource({ organizationId: organizationId! })
      );
    }
  }, [dispatch, organizationId, organizations]);

  useEffect(() => {
    if (projectId && organizationId) {
      dispatch(loadEnvironments({ projectId, organizationId }));
    }
  }, [dispatch, organizationId, projectId]);

  useEffect(() => {
    const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");
    // If we are on a project, the project reducer will take care of the organization loading
    if (
      organizationEnabled &&
      organizationId &&
      !projectId &&
      !organizations[organizationId]
    ) {
      dispatch(loadOrganization(organizationId));
    }
  }, [dispatch, organizationId, organizations, projectId]);

  useEffect(() => {
    if (project) {
      const subscriptionId = project.getSubscriptionId();
      dispatch(
        loadSubscription({
          organizationId: organizationId!,
          projectId: project.id,
          id: subscriptionId
        })
      );
    }
  }, [dispatch, organizationId, project]);

  const { pathname } = useLocation();

  const projectsArray = Object.values(projects);
  const projectOwner = isLoadingProjectList
    ? false
    : projectsArray.some(project =>
        isProjectOwner(project, user, organizations)
      );

  const organization = organizations[organizationId!];
  let outsideNavbar: React.ReactNode = null;
  let insideNavbar: React.ReactNode = null;

  const projectBillingUrl = getUrl({
    key: "organization.project.usageBasedBilling",
    props: {
      organizationId: organizationId || "",
      projectId: projectId || "",
      subscriptionId: subscription?.id || ""
    }
  });

  const isProjectSettingsPage = pathname.startsWith(
    `/${organizationId}/${projectId}/-/settings`
  );

  const encodedEnvironmentId = encodeURIComponent(environmentId!);
  const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");

  const isEnvironmentSettingsPage = pathname.startsWith(
    `/${organizationId}/${projectId}/${encodedEnvironmentId}/settings`
  );

  const classnames: string[] = [];
  if (pathname.includes("/create-project") || pathname.includes("/billing"))
    classnames.push("user-menu-fix");

  if (
    SUPPORT_PATH_MATCH.test(pathname) ||
    BILLING_PATH_MATCH.test(pathname) ||
    SETTINGS_PATH_MATCH.test(pathname)
  ) {
    classnames.push("settings");
  }

  if (
    insideNavConfig?.length ||
    BILLING_PATH_MATCH.test(pathname) ||
    SETTINGS_PATH_MATCH.test(pathname) ||
    new RegExp(
      `^/${organizationId}/-/(settings|billing)|^${projectBillingUrl}`
    ).test(pathname)
  ) {
    insideNavbar = (
      <InsideNavBar
        tabConfig={insideNavConfig!}
        username={username!}
        organizationId={organizationId!}
        currentPathName={pathname}
        organization={organization}
        projectId={projectId}
      />
    );
  } else if (isProjectSettingsPage || isEnvironmentSettingsPage) {
    outsideNavbar = null;
  } else if (
    project &&
    (!environment?.id ||
      [
        `/${organizationId}/${projectId}/${encodedEnvironmentId}/settings`,
        `/${organizationId}/${projectId}/${encodedEnvironmentId}/settings/variables`
      ].includes(decodeURIComponent(pathname)))
  ) {
    outsideNavbar = (
      <ProjectNavBar
        subscription={subscription}
        organizationId={organizationId!}
        projectId={projectId!}
        environmentId={environmentId}
        project={project}
        environment={environment}
      />
    );
  } else if (
    environment &&
    environment.id &&
    typeof environment.hasLink === "function"
  ) {
    outsideNavbar = (
      <EnvironmentNavBar
        organizationId={organizationId!}
        projectId={projectId!}
        environmentId={environmentId!}
        project={project!}
        environment={environment}
        currentPathName={pathname}
      />
    );
  } else if (
    !project &&
    (pathname === "/" ||
      new RegExp(`^/${organizationId}/{0,1}$`).test(pathname))
  ) {
    outsideNavbar = <SearchBar organizationId={organizationId!} />;
  }
  const showTestTrial =
    testMode?.trial?.startDate !== "" ||
    testMode?.trial?.expirationDate !== "" ||
    testMode?.trial?.status !== "";

  const hasDivider =
    organizationEnabled &&
    pathname !== "/" &&
    pathname !== `/${organizationId}` &&
    !pathname.startsWith("/-/users");

  return (
    <NavBarLayout className={classnames.join(" ")} role="navigation">
      {(!organizationEnabled && !showTestTrial) ||
        ((showTestTrial || (organizationEnabled && organizationId)) && (
          <BannerManager
            isProjectOwner={projectOwner}
            organizationId={organizationId}
            projects={projectsArray}
            user={user}
          />
        ))}
      <PageHeader
        id="page-header"
        reduceHeight={isProjectSettingsPage || isEnvironmentSettingsPage}
      >
        <MaxWidthContainer>
          <div className="navigation-bar" role="navigation">
            <NavBarLeftLayout>
              <Logo variant={project ? "withBreadcrumbs" : "standalone"} />

              {organizationEnabled && (
                <OrganizationsSwitcher organizationId={organizationId!} />
              )}

              <DunningTag
                organizationName={organizationId!}
                status={organization?.status}
              />

              <OrganizationDivider
                hasDivider={hasDivider}
                className="organization-divider"
              />
              <Breadcrumbs
                environmentId={environmentId}
                hiddenXs
                projectName={project?.title}
              />
            </NavBarLeftLayout>
            <NavBarRightLayout>
              <HelpMenu accountUrl={config.URL_ACCOUNTS} user={user!} />
              {announcements}

              {user && (
                <Menu
                  breadcrumbs={
                    <Breadcrumbs
                      environmentId={environmentId}
                      hiddenXs
                      projectName={project?.title}
                    />
                  }
                  accountUrl={config.URL_ACCOUNTS}
                  user={user}
                  organizations={organizations}
                  organizationId={organizationId}
                />
              )}
            </NavBarRightLayout>
          </div>
        </MaxWidthContainer>
        {insideNavbar}
      </PageHeader>
      {outsideNavbar}
    </NavBarLayout>
  );
};

export default NavBar;
