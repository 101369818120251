import React from "react";

import {
  CriticalIcon as Critical,
  InfoIcon as Information,
  SuccessIcon,
  NormalIcon as Normal
} from "@packages/ds";

import { Icon as StyledIcon } from "./Icon.styles";

export enum Variant {
  information = "information",
  warning = "warning",
  success = "success",
  error = "error"
}

export interface Props {
  /**
   * One of the available variants, will handle the kind of icon rendered.
   * Defautls to `information`
   */
  variant?: keyof typeof Variant;
}

const getIcon = (variant: Props["variant"]) => {
  switch (variant) {
    case Variant.warning:
      return <Normal color="var(--theme-warning-600)" />;
    case Variant.success:
      return <SuccessIcon color="var(--theme-success-600)" />;
    case Variant.error:
      return <Critical />;
    case Variant.information:
    default:
      return <Information color="var(--theme-graph-900)" />;
  }
};

const Icon = ({ variant = Variant.information }: Props) => (
  <StyledIcon>{getIcon(variant)}</StyledIcon>
);

export default Icon;
