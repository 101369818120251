import React, { useMemo } from "react";

import * as S from "./Trigger.styles";

export interface TriggerProps {
  children: React.ReactNode;
  className?: string;
}

const shouldWrapChildren = (children: React.ReactNode) => {
  const hasStringChildren = typeof children === "string";
  const hasChildWithProps =
    children &&
    React.Children.count(children) === 1 &&
    typeof children === "object" &&
    "props" in children &&
    children.props !== null;

  return (
    !children ||
    React.Children.count(children) > 1 ||
    hasStringChildren ||
    hasChildWithProps
  );
};

/**
 * Requires exactly one HTML DOM element as a children, otherwise, it will try
 * to provide a new one. If it can't the trigger won't be set and the tooltip
 * won't work.
 */
export const Trigger = ({ children, className }: TriggerProps) => {
  const wrapChildren = useMemo(() => shouldWrapChildren(children), [children]);

  return (
    <S.Layout className={wrapChildren ? undefined : className} asChild>
      {wrapChildren ? <span className={className}>{children}</span> : children}
    </S.Layout>
  );
};
