import Title from "./Title.styles";
import Code from "./Code.styles";
import BodyText from "./BodyText.styles";
import Label from "./Label.styles";

// Export components as a namespace
export const Components = {
  Title,
  Code,
  BodyText,
  Label
};
