import React from "react";
import { Icon, WrappingIconProps } from "../Icon";

type ArtyBitsProps = {
  version?: "v1" | "v2";
  themeFill?: string;
} & WrappingIconProps;

export const ArtyBitsIcon = ({
  version = "v1",
  themeFill,
  ...props
}: ArtyBitsProps) => {
  if (version === "v2") {
    return (
      <Icon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
        <g clipPath="url(#clip0_12487_55257)">
          <path
            d="M8.9136 17.6432C7.85266 17.6432 6.99259 16.815 6.99259 15.7934C6.99259 14.7718 7.85266 13.9437 8.9136 13.9437C9.97453 13.9437 10.8346 14.7718 10.8346 15.7934C10.8346 16.815 9.97453 17.6432 8.9136 17.6432Z"
            fill="#FF9B00"
          />
          <path
            d="M25.0205 7.60474C25.0205 11.3611 21.8582 14.4061 17.9571 14.4061C21.8582 14.4061 25.0205 17.4439 25.0205 21.2004H25.1091C25.1091 17.4439 28.2714 14.399 32.1725 14.399C28.2714 14.399 25.1091 11.3611 25.1091 7.60474H25.0205Z"
            fill="#FFA8AF"
          />
          <path
            d="M25.6485 7.60472C25.6485 11.788 22.0207 15.0108 17.7576 15.0108C17.4177 15.0108 17.1296 14.7334 17.1296 14.4061C17.1296 14.0788 17.4177 13.8014 17.7576 13.8014C22.0207 13.8014 25.6485 17.0242 25.6485 21.2075L25.0205 20.6027H25.1091L24.4811 21.2075C24.4811 17.0242 28.1088 13.8014 32.372 13.8014C32.7118 13.7943 33 14.0717 33 14.399C33 14.7262 32.7118 15.0037 32.372 15.0037C28.1088 15.0108 24.4811 11.788 24.4811 7.60472L25.1091 8.20945H25.0205C24.2151 8.20945 24.2151 7 25.0205 7H25.1091C25.449 7 25.7371 7.27746 25.7371 7.60472C25.7371 11.1335 28.7886 13.8014 32.372 13.8014L31.744 14.4061L32.372 15.0037C28.7886 15.0037 25.7371 17.6716 25.7371 21.2003C25.7371 21.5276 25.449 21.8051 25.1091 21.8051H25.0205C24.6806 21.8051 24.3924 21.5276 24.3924 21.2003C24.3924 17.6716 21.341 15.0037 17.7576 15.0037L18.3856 14.399L17.7576 13.8014C21.341 13.8014 24.3924 11.1335 24.3924 7.60472C24.3924 6.82925 25.6485 6.82925 25.6485 7.60472Z"
            fill="#FFA8AF"
          />
          <path
            d="M15.142 22.4027C15.142 25.3978 12.5339 27.7385 9.47509 27.7385C8.87662 27.7385 8.36682 27.2476 8.36682 26.6713C8.36682 26.095 8.87662 25.6041 9.47509 25.6041C12.5339 25.6041 15.142 27.9377 15.142 30.94L14.0338 29.8728H14.0855L12.9772 30.94C12.9772 27.9448 15.5853 25.6041 18.6442 25.6041C19.2426 25.6041 19.7524 26.095 19.7524 26.6713C19.7524 27.2476 19.2426 27.7385 18.6442 27.7385C15.5853 27.7385 12.9772 25.4049 12.9772 22.4027L14.0855 23.4698H14.0338C12.6078 23.4698 12.6078 21.3355 14.0338 21.3355H14.0855C14.684 21.3355 15.1938 21.8264 15.1938 22.4027C15.1938 23.2991 15.5706 24.1244 16.2503 24.7433C16.8931 25.3338 17.7206 25.597 18.6442 25.597L17.5359 26.6642L18.6442 27.7314C17.728 27.7314 16.9005 28.0017 16.2503 28.5851C15.5632 29.2112 15.1938 30.0293 15.1938 30.9257C15.1938 31.502 14.684 31.9929 14.0855 31.9929H14.0338C13.4353 31.9929 12.9255 31.502 12.9255 30.9257C12.9255 30.0293 12.5487 29.204 11.869 28.5851C11.2262 27.9946 10.3986 27.7314 9.47509 27.7314L10.5834 26.6642L9.47509 25.597C10.3913 25.597 11.2188 25.3267 11.869 24.7433C12.5561 24.1172 12.9255 23.2991 12.9255 22.4027C12.9255 21.0296 15.142 21.0296 15.142 22.4027Z"
            fill={themeFill}
          />
        </g>
        <defs>
          <clipPath id="clip0_12487_55257">
            <rect
              width="26"
              height="25"
              fill="white"
              transform="matrix(-1 0 0 1 33 7)"
            />
          </clipPath>
        </defs>
      </Icon>
    );
  }

  return (
    <Icon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <g clipPath="url(#clip0_8866_76385)">
        <path
          d="M31.0865 17.6428C32.1475 17.6428 33.0075 16.8147 33.0075 15.7931C33.0075 14.7715 32.1475 13.9434 31.0865 13.9434C30.0256 13.9434 29.1655 14.7715 29.1655 15.7931C29.1655 16.8147 30.0256 17.6428 31.0865 17.6428Z"
          fill="#FF9B00"
        />
        <path
          d="M14.9797 7.60547C14.9797 11.3619 18.1419 14.4068 22.043 14.4068C18.1419 14.4068 14.9797 17.4447 14.9797 21.2011H14.891C14.891 17.4447 11.7287 14.3997 7.82764 14.3997C11.7287 14.3997 14.891 11.3619 14.891 7.60547H14.9797Z"
          fill="#FFA8AF"
        />
        <path
          d="M14.3515 7.60472C14.3515 11.788 17.9793 15.0108 22.2424 15.0108C22.5823 15.0108 22.8704 14.7334 22.8704 14.4061C22.8704 14.0788 22.5823 13.8014 22.2424 13.8014C17.9793 13.8014 14.3515 17.0242 14.3515 21.2075L14.9795 20.6027H14.8909L15.5189 21.2075C15.5189 17.0242 11.8912 13.8014 7.62802 13.8014C7.28815 13.7943 7 14.0717 7 14.399C7 14.7262 7.28815 15.0037 7.62802 15.0037C11.8912 15.0108 15.5189 11.788 15.5189 7.60472L14.8909 8.20945H14.9795C15.7849 8.20945 15.7849 7 14.9795 7H14.8909C14.551 7 14.2629 7.27746 14.2629 7.60472C14.2629 11.1335 11.2114 13.8014 7.62802 13.8014L8.25604 14.4061L7.62802 15.0037C11.2114 15.0037 14.2629 17.6716 14.2629 21.2003C14.2629 21.5276 14.551 21.8051 14.8909 21.8051H14.9795C15.3194 21.8051 15.6076 21.5276 15.6076 21.2003C15.6076 17.6716 18.659 15.0037 22.2424 15.0037L21.6144 14.399L22.2424 13.8014C18.659 13.8014 15.6076 11.1335 15.6076 7.60472C15.6076 6.82925 14.3515 6.82925 14.3515 7.60472Z"
          fill="#FFA8AF"
        />
        <path
          d="M24.858 22.4031C24.858 25.3983 27.4661 27.7389 30.5249 27.7389C31.1234 27.7389 31.6332 27.248 31.6332 26.6717C31.6332 26.0955 31.1234 25.6046 30.5249 25.6046C27.4661 25.6046 24.858 27.9381 24.858 30.9404L25.9662 29.8732H25.9145L27.0228 30.9404C27.0228 27.9452 24.4147 25.6046 21.3558 25.6046C20.7574 25.6046 20.2476 26.0955 20.2476 26.6717C20.2476 27.248 20.7574 27.7389 21.3558 27.7389C24.4147 27.7389 27.0228 25.4054 27.0228 22.4031L25.9145 23.4703H25.9662C27.3922 23.4703 27.3922 21.3359 25.9662 21.3359H25.9145C25.316 21.3359 24.8062 21.8268 24.8062 22.4031C24.8062 23.2995 24.4294 24.1248 23.7497 24.7437C23.1069 25.3342 22.2794 25.5975 21.3558 25.5975L22.4641 26.6646L21.3558 27.7318C22.272 27.7318 23.0995 28.0021 23.7497 28.5855C24.4368 29.2116 24.8062 30.0297 24.8062 30.9261C24.8062 31.5024 25.316 31.9933 25.9145 31.9933H25.9662C26.5647 31.9933 27.0745 31.5024 27.0745 30.9261C27.0745 30.0297 27.4513 29.2045 28.131 28.5855C28.7738 27.995 29.6014 27.7318 30.5249 27.7318L29.4166 26.6646L30.5249 25.5975C29.6087 25.5975 28.7812 25.3271 28.131 24.7437C27.4439 24.1177 27.0745 23.2995 27.0745 22.4031C27.0745 21.03 24.858 21.03 24.858 22.4031Z"
          fill={themeFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_8866_76385">
          <rect
            width="26"
            height="25"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};
