import { Typography } from "@packages/ds";
import styled from "styled-components";

export const Title = styled(Typography.BodyText).attrs<{ weight?: string }>(
  props => ({
    size: "small",
    weight: props.weight || "semiBold"
  })
)`
  color: var(--mode-text-neutral-default-weak);
`;

export default Title;
