import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { selectUserProjectById } from "Reducers/app/selectors";
import { organizationsSelector } from "Reducers/organization";
import {
  UpdateProjectType,
  UpdateProjectReturnPayload,
  gitProjectSelector
} from "Reducers/project/project";

import type { Project } from "@packages/client";
import type { AsyncThunkOptionType } from "Reducers/types";

export const updateProject = createAsyncThunk<
  UpdateProjectReturnPayload,
  UpdateProjectType,
  AsyncThunkOptionType
>(
  "app/project_reducer/update",
  async (
    { organizationDescriptionId, projectDescriptionId, data },
    { rejectWithValue, getState }
  ) => {
    try {
      const projectMe = selectUserProjectById(getState(), {
        projectId: projectDescriptionId
      });

      const project = gitProjectSelector(getState(), {
        organizationId: organizationDescriptionId,
        projectId: projectDescriptionId
      });

      if (!project) {
        return rejectWithValue("project does not exist");
      }

      const organizations = organizationsSelector(getState());
      const projectResult = await project.update(data);

      return {
        project: projectResult.getEntity() as Project,
        organizations,
        projectMe
      };
    } catch (err) {
      logger(err, {
        action: "project_update",
        organizationDescriptionId,
        projectDescriptionId,
        data
      });

      return rejectWithValue(err);
    }
  }
);
