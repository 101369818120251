import { Root, Provider } from "@radix-ui/react-tooltip";
import { Tooltip as DefaultTooltip } from "./Tooltip";
import { Trigger } from "./Trigger";
import { Arrow } from "./Arrow.styles";
import { Content } from "./Content.styles";

// Create the namespace object with all subcomponents
const TooltipNamespace = Object.assign(DefaultTooltip, {
  Root,
  Provider,
  Trigger,
  Content,
  Arrow
});

export type { TooltipProps } from "./Tooltip";
export type { TriggerProps } from "./Trigger";
export type { ArrowProps } from "./Arrow.styles";
export type { ContentProps } from "./Content.styles";

export { TooltipNamespace as Tooltip };
