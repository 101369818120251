import React, { useEffect, useMemo, useState } from "react";

import moment from "moment";
import { useIntl } from "react-intl";

import { Tooltip } from "ds/Tooltip";

import {
  TimeInput,
  TimeInputContainer,
  TimeInputWrapper,
  TimeText
} from "./styles";
import { TimePickerPropType } from "./types";
import { parseTime } from "./utils";

const TimePicker = ({ start, end, onChange }: TimePickerPropType) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const intl = useIntl();

  useEffect(() => {
    start && setStartTime(moment(start).format("HH:mm"));
  }, [start]);

  useEffect(() => {
    end && setEndTime(moment(end).format("HH:mm"));
  }, [end]);

  const parsedStartTime = useMemo(() => {
    if (!startTime) {
      return;
    }
    const parsedTime = parseTime(startTime);
    const { minute, hour, isValid } = parsedTime;
    if (isValid) {
      const startUpdate = moment(start || new Date())
        .hour(hour)
        .minute(minute)
        .toDate();
      onChange({ start: startUpdate, end });
    }
    return parsedTime;
    // Reason: adding "end" causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  const { isValid } = useMemo(() => {
    if (!endTime) {
      return { isValid: false };
    }
    const parsedTime = parseTime(endTime);
    const { minute, hour, isValid } = parsedTime;

    if (isValid) {
      const endTimeDate = moment(end || new Date())
        .hour(hour)
        .minute(minute)
        .toDate();
      onChange({ end: endTimeDate, start });
    }
    return parsedTime;
    // Reason: adding "end" causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime]);

  return (
    <TimeInputContainer>
      <TimeInputWrapper>
        <TimeText>{moment(start).format("MMM DD")}</TimeText>
        <Tooltip
          tooltip={intl.formatMessage({ id: "time_input_tip" })}
          open={!parsedStartTime?.isValid}
          analyticId="time_input_tip"
        >
          <div>
            <TimeInput
              data-testid="start-range-time"
              data-lpignore="true"
              autoComplete="false"
              isValid={parsedStartTime?.isValid}
              value={startTime}
              onChange={({ target }) =>
                setStartTime(parseTime(target.value, startTime).time)
              }
            />
          </div>
        </Tooltip>
      </TimeInputWrapper>

      <TimeInputWrapper className="float-right">
        <TimeText>{moment(end || start).format("MMM DD")}</TimeText>
        <Tooltip
          tooltip={intl.formatMessage({ id: "time_input_tip" })}
          open={!isValid}
          analyticId="time_input_tip"
        >
          <div>
            <TimeInput
              data-testid="end-range-time"
              autoComplete="false"
              data-lpignore="true"
              isValid={isValid}
              value={endTime}
              onChange={({ target }) => {
                setEndTime(parseTime(target.value, endTime).time);
              }}
            />
          </div>
        </Tooltip>
      </TimeInputWrapper>
    </TimeInputContainer>
  );
};

export default TimePicker;
