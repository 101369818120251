import config from "console_config";
import {
  SAFE_ROLES,
  PROJECT_ID_FIELD,
  ENVIRONMENT_ID_FIELD
} from "Constants/constants";
import { meSelector, selectCurrentUserTeams } from "Reducers/app/selectors";
import { getFeatureFlag } from "Reducers/featureFlags";

import getUrl from "./getUrl";

import type {
  Environment,
  Me,
  Organization,
  OrganizationProfile,
  Project,
  Subscription
} from "@packages/client";
import type { OrganizationProfileProperties } from "Reducers/organization/profile";
import type { AuthProviders } from "Reducers/organization/settings/connectedAccounts";
import type { GetState, RootState } from "Store/configureStore";

// A hashing algorithm used to convert text of any length into a fixed-size string.
// Each output produces a SHA - 512 length of 512 bits(64 bytes).
// This algorithm is commonly used for email addresses hashing, password hashing, and digital record verification.
// Source https://stackoverflow.com/questions/55926281/how-do-i-hash-a-string-using-javascript-with-sha512-algorithm
export const sha512 = (stringValue: string) => {
  return crypto.subtle
    .digest("SHA-512", new TextEncoder().encode(stringValue))
    .then(buf => {
      return Array.prototype.map
        .call(new Uint8Array(buf), x => ("00" + x.toString(16)).slice(-2))
        .join("");
    });
};

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
export const getUserLanguage = () => {
  const userLanguage =
    navigator.languages?.[0] ||
    navigator.language ||
    navigator.userLanguage ||
    "en";
  // Split locales with a region code
  const languageWithoutRegionCode = userLanguage
    .toLowerCase()
    .split(/[_-]+/)[0];
  const supportedLanguages = ["en", "fr"];
  let language = "en-US";

  if (supportedLanguages.indexOf(languageWithoutRegionCode) > -1) {
    language = userLanguage;
  }

  return { language, languageWithoutRegionCode };
};

export const getRouteUrl = (route: {
  scheme: string;
  host: string;
  path: string;
}) => `${route.scheme}://${route.host}${route.path}`;

export const normalize = <
  T extends { [_ in K]: string } & { [k: string | number | symbol]: any },
  K extends keyof T
>(
  array: T[],
  idFieldName: K
) =>
  array?.reduce(
    (accumulator, currentObject) => {
      if (!currentObject) {
        return accumulator;
      }
      accumulator[currentObject[idFieldName]] = currentObject;
      return accumulator;
    },
    {} as Record<T[K], T>
  );

export const getEnvironmentParents = (
  environments: Environment[],
  childId: string,
  parents: Environment[] = []
): Environment[] => {
  const environment = environments.find(env => env.id === childId);
  const parent = environments.find(env => env.id === environment?.parent);

  if (!parent) {
    throw new Error(
      `Environment ${environment?.parent || ""} could not be found`
    );
  }

  if (parent.parent) {
    return parents.concat(
      getEnvironmentParents(environments, parent.id, parents)
    );
  }

  return parents.concat(parent);
};

export const getProjectId = (
  getState: GetState,
  projectDescriptionId?: string
) => {
  const me = meSelector(getState());
  const project = me?.projects?.find(
    p => p[PROJECT_ID_FIELD] === projectDescriptionId
  );

  if (!project) {
    return projectDescriptionId;
  }

  return project.id;
};

export const getOrganizationId = (
  getState: GetState | RootState,
  organizationDescriptionId: string | undefined
) => {
  // extended to allow passing the state object for flexibility of use in
  // places like useSelector hook
  const state = typeof getState === "function" ? getState() : getState;
  return organizationDescriptionId
    ? state.organization.orgByDescriptionField[organizationDescriptionId]?.id
    : undefined;
};

// TODO: this should be moved to a selector
export const getTeamId = (getState: GetState, teamDescriptionId: string) => {
  const teams = selectCurrentUserTeams(getState());
  const team = teams?.find(team => team["name"] === teamDescriptionId);

  return team?.id;
};

export const getEnvironmentId = (
  getState: GetState,
  organizationDescriptionId: string,
  projectDescriptionId: string,
  environmentDescriptionId: string
) => {
  const environment =
    getState().environment.data?.[organizationDescriptionId]?.[
      projectDescriptionId
    ]?.[environmentDescriptionId];

  return environment?.id;
};

export const getEnvironmentDescriptionId = (
  getState: GetState,
  organizationDescriptionId: string,
  projectDescriptionId?: string,
  environmentId?: string
) => {
  if (!projectDescriptionId) return;

  const environment = Object.values(
    getState().environment.data?.[organizationDescriptionId]?.[
      projectDescriptionId
    ] ?? {}
  ).find(e => e?.id === environmentId);

  return environment?.[ENVIRONMENT_ID_FIELD];
};

export const getIntegrationDescriptionId = (
  getState: GetState,
  projectDescriptionId: string,
  integrationId: string
) => {
  const integration = Object.values(
    getState().integration.data[projectDescriptionId] ?? {}
  ).find(i => i?.id === integrationId);

  return integration?.id;
};

export const goToEnvironment = <T>(
  pushFct: (arg0: string) => T,
  organizationId: string,
  projectId: string,
  environmentId: string
) =>
  pushFct(
    getUrl({
      key: "organization.project.environment",
      props: { organizationId, projectId, environmentId }
    })
  );

export const getEnvironmentGitCommand = (
  project: Project,
  environment: Environment,
  machineName: string,
  type: string
) => {
  if (!project?.repository) {
    return;
  }

  if (machineName === "magento") {
    machineName = "magento-cloud";
  }

  // Escape an environment (Git branch) name as a shell argument. We do not
  // need to worry about double escaping, because Git branch names cannot
  // contain backslashes.
  function escapeNameAsShellArg(str: string) {
    return str.replace(/(["$`' ()])/g, "\\$1");
  }

  if (type === "cli" && environment) {
    return `${machineName} get ${project.id} -e ${escapeNameAsShellArg(
      environment.name
    )}`;
  }

  if (type === "cli-project") {
    return `${machineName} get ${project.id}`;
  }
  // const first = project.title.replace(" ", "-");
  const title = project?.title?.replace(/[^\w\s]/gi, "").replace(/\s+/g, "-");
  if (type === "git-project") {
    return `git clone ${project.repository.url} ${title}`;
  }

  return `git clone --branch ${escapeNameAsShellArg(environment.name)} ${
    project.repository.url
  } ${title}`;
};

export const getGitRemoteCommand = (project: Project, machineName: string) => {
  if (!project?.repository) {
    return;
  }

  if (machineName === "magento") {
    machineName = "magento-cloud";
  }

  return `git remote add ${machineName} ${project.repository.url}`;
};

export const checkGoLive = (project?: Project, organization?: Organization) => {
  if (organization?.hasLink && !organization.hasLink("estimate-subscription")) {
    return "no-permission";
  }
  if (!project) return;
  if (project.subscription?.plan === "development") {
    if (project._links?.["#subscription_change"]) {
      return "development";
    } else {
      return "no-permission";
    }
  } else {
    if (!project.default_domain) {
      if (project._links?.["#edit"]) {
        return "domain";
      } else {
        return "no-permission";
      }
    } else {
      return "live";
    }
  }
};

// If there's no address or login settings, then HTTP access is disabled.
export const httpStatusDisplay = (environment?: Environment) => {
  if (!environment) return false;

  const http_access = environment.http_access;

  if (http_access?.is_enabled) {
    if (
      http_access.addresses?.length === 0 &&
      Object.entries(http_access.basic_auth ?? {}).length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const formatAsCurrency = (amount: number, currency?: string) => {
  if (typeof currency === "undefined") {
    throw new Error("A currency must be defined");
  }
  const { language } = getUserLanguage();
  const format = new Intl.NumberFormat(language, {
    style: "currency",
    currency,
    minimumFractionDigits: 2
  });
  return format.format(amount);
};

const sortProjectListByOwner = (
  a: Project,
  b: Project,
  settings: { sortOrder: "ascend" | "descend"; sortType: string },
  organizations: Record<string, Organization>
) => {
  if (a.owner_info?.type === "user") {
    if (a.owner_info.username && b.owner_info?.username) {
      if (settings.sortOrder === "descend") {
        if (a.owner_info.username > b.owner_info.username) return -1;
        if (a.owner_info.username < b.owner_info.username) return 1;
        return 0;
      }
      if (a.owner_info.username < b.owner_info.username) return -1;
      if (a.owner_info.username > b.owner_info.username) return 1;
      return 0;
    }
  }

  if (a?.organization_id && b?.organization_id) {
    const o1 = organizations[a.organization_id];
    const o2 = organizations[b.organization_id];
    const o1Label = o1?.label?.toLowerCase();
    const o2Label = o2?.label?.toLowerCase();
    if (o1Label && o2Label) {
      if (settings.sortOrder === "descend") {
        if (o1Label > o2Label) return -1;
        if (o1Label < o2Label) return 1;
        return 0;
      }
      if (o1Label < o2Label) return -1;
      if (o1Label > o2Label) return 1;
      return 0;
    }
  }

  return 0;
};

export const sortProjectList = (
  a: Project,
  b: Project,
  settings: { sortOrder: "ascend" | "descend"; sortType: string },
  organizations: Record<string, Organization>,
  {
    idFieldName,
    titleFieldName
  }: { idFieldName: keyof Project; titleFieldName: keyof Project } = {
    idFieldName: "id",
    titleFieldName: "title"
  }
) => {
  if (
    settings.sortType === "owner" &&
    ((a.owner_info && b.owner_info) || (a.organization_id && b.organization_id))
  ) {
    return sortProjectListByOwner(a, b, settings, organizations);
  }
  if (settings.sortType === "region" && a.region_label && b.region_label) {
    if (settings.sortOrder === "descend") {
      if (a.region_label.toLowerCase() > b.region_label.toLowerCase())
        return -1;
      if (a.region_label.toLowerCase() < b.region_label.toLowerCase()) return 1;
      return 0;
    }
    if (a.region_label.toLowerCase() < b.region_label.toLowerCase()) return -1;
    if (a.region_label.toLowerCase() > b.region_label.toLowerCase()) return 1;
    return 0;
  }

  const aId = a[idFieldName];
  const bId = b[idFieldName];
  if (
    settings.sortType === "id" &&
    aId &&
    bId &&
    typeof aId === "string" &&
    typeof bId === "string"
  ) {
    const aIdLowercase = aId.toLowerCase();
    const bIdLowercase = bId.toLowerCase();
    if (settings.sortOrder === "descend") {
      if (aIdLowercase > bIdLowercase) return -1;
      if (aIdLowercase < bIdLowercase) return 1;
      return 0;
    }
    if (aIdLowercase < bIdLowercase) return -1;
    if (aIdLowercase > bIdLowercase) return 1;
    return 0;
  }
  if (settings.sortType === "plan" && a.plan && b.plan) {
    if (settings.sortOrder === "descend") {
      if (a.plan.toLowerCase() > b.plan.toLowerCase()) return -1;
      if (a.plan.toLowerCase() < b.plan.toLowerCase()) return 1;
      return 0;
    }
    if (a.plan.toLowerCase() < b.plan.toLowerCase()) return -1;
    if (a.plan.toLowerCase() > b.plan.toLowerCase()) return 1;
    return 0;
  }
  if (settings.sortType === "created_at" && a.created_at && b.created_at) {
    if (settings.sortOrder === "descend") {
      if (a.created_at > b.created_at) return -1;
      if (a.created_at < b.created_at) return 1;
      return 0;
    }
    if (a.created_at < b.created_at) return -1;
    if (a.created_at > b.created_at) return 1;
    return 0;
  }
  if (settings.sortOrder === "descend" && a.title && b.title) {
    if (a.title.toLowerCase() > b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() < b.title.toLowerCase()) return 1;
    return 0;
  }

  const aTitle = a[titleFieldName];
  const bTitle = b[titleFieldName];
  if (
    aTitle &&
    bTitle &&
    typeof aTitle === "string" &&
    typeof bTitle === "string"
  ) {
    const aTitleLowerCase = aTitle.toLowerCase();
    const bTitleLowerCase = bTitle.toLowerCase();
    if (aTitleLowerCase < bTitleLowerCase) return -1;
    if (aTitleLowerCase > bTitleLowerCase) return 1;
  }
  if (!aTitle && bTitle) {
    return -1;
  }
  return 0;
};

export const sortBy = <
  T extends { [_ in K]: string } & { [k: string]: any },
  K extends keyof T
>(
  list: T[],
  field: K
) => {
  if (!list[0] || !Object.getOwnPropertyDescriptor(list[0], field)) return list;
  return [...list].sort((a, b) => {
    return a[field].localeCompare(b[field]);
  });
};

// regionLabel looks like "Europe - Ireland (eu-3)"
// we want to return:
// {
//   title: "Europe - Ireland",
//   suffix: "eu-3"
// }
export const getRegionLabel = (regionLabel = "") => {
  const title = regionLabel.split("(")[0].trim();
  const titleSuffix = regionLabel.substring(
    regionLabel.lastIndexOf("(") + 1,
    regionLabel.lastIndexOf(")")
  );

  if (title.length < 1) {
    return {
      title: regionLabel,
      suffix: ""
    };
  }
  return {
    title,
    suffix: titleSuffix
  };
};

export const hasSafeRole = (roles?: string[]) => {
  return roles?.some(role => SAFE_ROLES.includes(role));
};

export const orderTotal = (
  components: Record<string, { amount: number }>,
  currency: string
) => {
  const total = Object.values(components).reduce((total, component) => {
    return (total = total + component.amount);
  }, 0);

  return formatAsCurrency(total, currency);
};

export const isDefined = (variable: unknown) => {
  return !(typeof variable === "undefined" || variable === null);
};

export const isJson = (str: unknown): str is string => {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    return ["[object Object]", "[object Array]"].includes(
      Object.prototype.toString.call(result)
    );
  } catch {
    return false;
  }
};

export const hasHtml = (str: string) => {
  const htmlRegex = new RegExp("<.+?>");
  return htmlRegex.test(str);
};

export const interpolateURL = (
  templateURL: string,
  params: Record<string, string>
) => {
  const paramsRegex = /(:[0-z]+)/g;
  const paramsList = templateURL.match(paramsRegex);

  if (!paramsList?.length) {
    return templateURL;
  }

  let parsedUrl = templateURL;

  for (let i = 0; i < paramsList.length; i++) {
    const paramName = paramsList[i].substring(1);
    const fieldName = paramName;

    if (params[fieldName]) {
      parsedUrl = parsedUrl.replace(paramsList[i], params[fieldName]);
    }
  }

  return parsedUrl;
};

export const isProjectOwner = (
  project: Project | undefined,
  me: Me | undefined,
  organizations: Record<string, Organization | undefined> = {}
) => {
  if (project?.owner_info?.type === "user") {
    return project?.owner === me?.id;
  }

  if (project?.organization_id) {
    // If the organization object has a billing link, this user has some owner rights
    return !!organizations[project.organization_id]?.getLink("billing");
  }

  return false;
};

export const getOwnerInfoLabel = ({
  subscription,
  organizations,
  project
}: {
  subscription?: Subscription;
  organizations: Record<string, Organization>;
  project?: Project;
}) => {
  if (
    !getFeatureFlag("ENABLE_ORGANIZATION") ||
    (project || subscription)?.owner_info?.type === "user"
  ) {
    return (project || subscription)?.owner_info?.display_name;
  }
  const organizationId =
    subscription?.organization_id ||
    subscription?.owner ||
    project?.organization_id;

  if (!organizationId) {
    return;
  }

  return organizations[organizationId]?.label;
};

export const getOwnerInfoName = ({
  project,
  subscription,
  organizations
}: {
  project?: Project;
  subscription?: Subscription;
  organizations: Record<string, Organization | undefined>;
}) => {
  if (
    !getFeatureFlag("ENABLE_ORGANIZATION") ||
    (project || subscription)?.owner_info?.type === "user"
  ) {
    return (project || subscription)?.owner_info?.username;
  }
  const organizationId =
    subscription?.organization_id ||
    subscription?.owner ||
    project?.organization_id;

  if (!organizationId) {
    return;
  }

  return organizations[organizationId]?.name;
};

export const getTrial = (
  organizationProfile?: OrganizationProfile | OrganizationProfileProperties
): boolean => {
  return organizationProfile?.current_trial?.active;
};

export const arrayToObject = <K extends string, V>(
  array: [key: K, value: V][]
) =>
  array.reduce(
    (result, [key, value]) => ({ ...result, [key]: value }),
    {} as Record<K, V>
  );

export const capitalize = <T>(s: T) => {
  if (typeof s !== "string") return s;

  return s ? s.charAt(0).toUpperCase() + s.slice(1) : "";
};

export const docsUrl = config.CUSTOM_URL_DOCS
  ? String(config.CUSTOM_URL_DOCS)
  : "https://docs.platform.sh";

export const getSubscriptionEditUrl = ({
  subscription,
  project,
  organization
}: {
  subscription?: Subscription;
  project?: Project;
  organization?: Organization;
} = {}) => {
  let hasPermission = false;

  if (getFeatureFlag("ENABLE_ORGANIZATION")) {
    if (organization) {
      hasPermission =
        organization?.hasLink &&
        !!(
          organization?.hasLink?.("orders") ||
          organization?.hasLink?.("estimate-subscription")
        );
    } else {
      hasPermission = subscription?.hasLink?.("update") ?? false;
    }
  } else {
    hasPermission =
      project?.hasPermission?.("#subscription_change") || !!subscription;
  }

  if (!hasPermission) return null;

  if (getFeatureFlag("ENABLE_ORGANIZATION")) {
    const url =
      subscription?.hasLink?.("plan_uri") &&
      subscription?.getLink("plan_uri", false);
    if (!url) return null;
    return {
      url,
      external: false,
      hasPermission
    };
  }

  if (!project?.plan_uri) return null;
  return {
    url: project.plan_uri,
    external: false,
    hasPermission
  };
};

export const domainMatches = (domain: string, input: string) =>
  new RegExp(`^(http[s]?://)?${domain}(/.*)?$`, "gi").test(input);

export const downloadBlob = (
  data: BlobPart,
  type: BlobPropertyBag,
  downloadName: string
) => {
  const blob = new Blob([data], type);
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, downloadName);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = downloadName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }
};

export const ellipsis = (text = "", maxLength = 10) => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const getGitTemplateName = (templateURL?: string) => {
  if (typeof templateURL !== "string") return "";

  const matchGit = templateURL.match(
    /^git:\/\/github\.com\/(.*)\/(?<repo>.*).git@master$/
  );
  if (matchGit?.groups) return matchGit.groups.repo || "";

  const matchHttps = templateURL.match(
    /^https:\/\/github\.com\/(?<org>.*)\/(?<repo>.*)((\.git@master)|(\/(.*){3}\/(?<tpl>.*)\/\.platform\.template\.yaml))/
  );
  if (matchHttps?.groups)
    return matchHttps.groups.tpl || matchHttps.groups.repo || "";

  return "";
};

export const toQueryString = (params: Record<string, string | null>) => {
  return Object.entries(params)
    .map(([key, value]) => {
      if (value) return `${key}=${value}`;
      return "";
    })
    .filter(Boolean)
    .join("&");
};

export const envFlagToBoolean = (flag: unknown) => {
  return typeof flag == "string"
    ? flag.toLocaleLowerCase() === "true"
    : Boolean(flag);
};

export const getAuthProviders = (): Record<string, AuthProviders[]> => {
  const providers =
    typeof config.CUSTOM_FEDERATED_AUTH_PROVIDERS === "string"
      ? JSON.parse(config.CUSTOM_FEDERATED_AUTH_PROVIDERS)
      : config.CUSTOM_FEDERATED_AUTH_PROVIDERS;

  return {
    providers: providers instanceof Array ? providers : []
  };
};

/**
 * Escape regular expression special character in a string
 * so that when it is used as a matching string,
 * it has no specially meaning
 * @param s string containing the special characters
 * @returns string with all regex character properly escpaed
 */
export const escapeRegexSpecialChars = (s: string) =>
  s?.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

export const getGitRepoName = (templateURL: string = "") => {
  const regex = /^(git|https|http):\/\/github\.com\/(.*)\/(.*).git(.*)$/;
  const match = templateURL.match(regex);
  return match ? match[3] : "";
};

export const isSafeUrl = (text: string | undefined) => {
  if (!text) return false;

  try {
    const url = new URL(text);
    return ["http:", "https:"].includes(url.protocol);
  } catch {
    return false;
  }
};

export const sleep = (timeInMs: number) =>
  new Promise(accept => window.setTimeout(accept, timeInMs));

export const arrayIsDeepEqual = <T>(a: Array<T>, b: Array<T>) => {
  let equals = true;
  const length = Math.max(a.length, b.length);
  for (let i = 0; i < length; i++) {
    equals = equals && a[i] === b[i];
  }
  return equals;
};

export const sortEnvironmentTypes = (a: string, b: string) => {
  const [typeA] = a.split(":");
  const [typeB] = b.split(":");

  const order = {
    production: 0,
    staging: 1,
    development: 2
  };

  return order[typeA] - order[typeB];
};

export const objectEntries = <
  T extends Parameters<ObjectConstructor["entries"]>[0]
>(
  o: T | null | undefined
) =>
  Object.entries(o ?? {}) as {
    [K in keyof T]-?: [K, T[K]];
  }[keyof T][];

export const objectKeys = <T extends Parameters<ObjectConstructor["keys"]>[0]>(
  o: T | null | undefined
) =>
  Object.keys(o ?? {}) as {
    [K in keyof T]-?: K;
  }[keyof T][];

export const roundedNumber = (
  number: number,
  maxDecimals: number = 2
): number => {
  const decimals = Math.pow(10, maxDecimals);
  return Math.floor(number * decimals) / decimals;
};

export const splitStringByChar = (inputString: string, charToSplit: string) => {
  const result = {
    pathname: inputString,
    hash: ""
  };
  const hashIndex = inputString?.indexOf(charToSplit);

  if (hashIndex !== -1) {
    result.pathname = inputString?.substring(0, hashIndex);
    result.hash = inputString?.substring(hashIndex + 1);
  }

  return result;
};

export const isDg2 = (
  enterpriseTag: string | undefined,
  enterpriseDeployment: boolean
) => enterpriseTag !== "" && enterpriseDeployment;

export const isDg3 = (
  enterpriseTag: string | undefined,
  dedicatedDeployment: boolean
) => enterpriseTag !== "" && dedicatedDeployment;

export const getTrialConfig = ({
  trialDays,
  showCta
}: {
  trialDays?: number;
  showCta: boolean;
}) => {
  let configTrialStages = config.CUSTOM_TRIAL_STAGES;
  if (!config.CUSTOM_TRIAL_STAGES || config.CUSTOM_TRIAL_STAGES.length === 0) {
    // What we are doing here is define an standard trial stages that works in this way:
    // 1. The first stage is the normal "free trial" one, from the beggining of the trial until 2 days before it ends
    // 2. The second stage is the "trial expires soon" one, from 1 day before the expiry date
    // 3. The last stage is the "suspended" one, after the trial has ended
    // This are a set of normative stages that we can use so that we don't hard code the total trial lenght
    // giving accounts the possibility to change the trial without having to do twin release with console
    // (as with this methodology we aren't hard coding the total trial length to, for example, 15 days)
    configTrialStages = [
      {
        daysRemaining: Infinity,
        msg: null,
        priority: null,
        tagText: "free_trial",
        tagVariant: "rose"
      },
      {
        daysRemaining: 1,
        includeIcons: false,
        msg: "state_2",
        priority: "high",
        subscribeMsg: "state_2_cta",
        tagText: "trial_ending",
        tagVariant: "yellow"
      },
      {
        daysRemaining: 0,
        includeIcons: false,
        msg: "state_3",
        priority: "high",
        showModal: true,
        subscribeMsg: "state_3_cta",
        tagText: "suspended",
        tagVariant: "red"
      }
    ];
  }

  const trialStages = [...configTrialStages];

  if (typeof trialDays === "undefined") {
    const stage = trialStages[0];
    return {
      priority: stage.priority,
      msg: stage.subscribeMsg && showCta ? stage.subscribeMsg : stage.msg,
      showModal: stage.showModal,
      tagText: stage.tagText,
      tagVariant: stage.tagVariant || "rose"
    };
  }

  for (let index = 0; index < trialStages.length; index++) {
    const stage = trialStages[index];
    const nextStage = trialStages[index + 1];

    if (
      (index === 0 && trialDays > stage?.daysRemaining) ||
      (trialDays <= stage?.daysRemaining &&
        trialDays > (nextStage?.daysRemaining ?? -Infinity))
    ) {
      return {
        priority: stage.priority,
        msg: stage.subscribeMsg && showCta ? stage.subscribeMsg : stage.msg,
        showModal: stage.showModal,
        tagText: stage.tagText,
        tagVariant: stage.tagVariant || "rose"
      };
    }
  }
};

export const formatBranchName = (branchName: string): string =>
  branchName.trim().replace(/\s|'/g, "-").toLowerCase();

export const includesAny = (arr: string[], values: string[]) =>
  values.some(v => arr.includes(v));

export const memorySizes = [
  "B",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB"
];

/**
 * Formats a given memory size in bytes into a human-readable string
 * representation that uses the appropriate memory unit without overflowing.
 *
 * @param bytes The memory size in bytes.
 * @returns A string representation of the memory size with an appropriate unit.
 */
export const autoFormatBytes = (bytes: number) => {
  const conversionFactors = [
    1, // Bytes (B)
    1024, // Kilobytes (KB)
    1024 * 1024, // Megabytes (MB)
    1024 * 1024 * 1024, // Gigabytes (GB)
    1024 * 1024 * 1024 * 1024, // Terabytes (TB)
    1024 * 1024 * 1024 * 1024 * 1024 // Petabytes (PB)
  ];
  if (bytes <= 0 || !bytes) {
    return "0B";
  }

  const index = memorySizes.findIndex(
    (_, i) => bytes < conversionFactors[i + 1]
  );
  const formattedValue = (bytes / conversionFactors[index]).toFixed(2);
  const formattedUnit = memorySizes[index];
  return `${formattedValue}${formattedUnit}`;
};

export const isEnvInactiveOrDeleting = (status?: string) => {
  return status === "inactive" || status === "deleting";
};

export const copyToClipboard = async (text: string) => {
  if (navigator?.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
    } catch {
      return;
    }
  }
};

/**
 * Normalizes a URL path by replacing consecutive slashes with a single slash.
 * Preserves the protocol (e.g., "http://") during normalization.
 *
 * @param {string} url - The URL to normalize.
 * @returns {string} The normalized URL.
 */
export const normalizeUrlPath = (url: string) => {
  return url.replace(/([^:]\/)\/+/g, "$1");
};
