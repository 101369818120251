import React from "react";

import { Typography } from "@packages/ds";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, ButtonWrapper } from "ds/Button";
import Modal from "ds/Modal";
import { selectCurrentUserUsername } from "Reducers/app/selectors";
import {
  selectFeatureUsageBasedBillingEnabled,
  selectFeatureOrganizationsEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { useAppSelector } from "Store/hooks";

import * as S from "./styles";

type ModalTrialProps = {
  closeModal: () => void;
  isOpen: boolean;
  kind: string;
  organizationNameId?: string;
};
const ModalTrial = ({
  closeModal,
  isOpen,
  kind,
  organizationNameId
}: ModalTrialProps) => {
  const intl = useIntl();
  const organizationEnabled = useAppSelector(selectFeatureOrganizationsEnabled);
  const navigate = useNavigate();
  const username = useSelector(selectCurrentUserUsername);
  const usageBasedBillingEnabled = useAppSelector(
    selectFeatureUsageBasedBillingEnabled
  );

  const goToBilling = e => {
    e?.preventDefault();
    closeModal();
    if (organizationEnabled && organizationNameId) {
      const billingPath = usageBasedBillingEnabled
        ? `/${organizationNameId}/-/billing/details`
        : `/${organizationNameId}/-/billing`;
      navigate(billingPath);
    } else {
      navigate(`/-/users/${username}/billing`);
    }
  };

  return (
    <Modal isOpen={isOpen} size="small" onOpenChange={closeModal}>
      <Typography.Title tag="h2">
        {intl.formatMessage({ id: `modal.trial.limit.${kind}.title` })}
      </Typography.Title>
      <Typography.BodyText size="medium" weight="regular">
        {intl.formatMessage(
          { id: `modal.trial.limit.${kind}.details` },
          {
            a: txt => (
              <S.Link href={intl.formatMessage({ id: "links.contact" })}>
                {txt}
              </S.Link>
            ),
            br: <br />
          }
        )}
      </Typography.BodyText>
      <ButtonWrapper spacing="modal" justifyContent="end">
        <Button
          id={`cancel-modal-button`}
          variant="secondary"
          onClick={closeModal}
        >
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Button
          id={`add-billing-modal-button`}
          type="submit"
          onClick={goToBilling}
        >
          {intl.formatMessage({ id: `modal.trial.limit.${kind}.button` })}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default ModalTrial;
