import { Typography } from "@packages/ds";
import styled from "styled-components";

import OverflowList, { HiddenCount } from "ds/OverflowList";

import InputEmail from "./InputEmail";

export { PermissionsWrapper } from "src/organization/pages/settings/pages/components/Permissions";

export const Input = styled(InputEmail)`
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
`;

export const OverflowListActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 24px;
`;

export const TrialNote = styled.div`
  margin-top: 24px;
  white-space: pre-wrap;
`;

export const TitleTagWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

export const PermissionsLabel = styled.div`
  margin-bottom: 16px;

  & > :nth-child(2) {
    margin-top: 8px;
    & > :first-child {
      margin-left: 8px;
    }
  }
`;

export const EmailList = styled(OverflowList)`
  ${HiddenCount} {
    bottom: 0;
  }
` as typeof OverflowList;

export const PriceIncreaseNote = styled(Typography.BodyText)`
  margin-top: 24px;
`;
