import React from "react";

import { useSelector } from "react-redux";

import { MenuActiveTag } from "Containers/testing-widget/TestingModal.styles";
import useMediaQuery from "Hooks/useMediaQuery";
import { selectUserProfileByMeUsername } from "Reducers/profile";
import { isActiveSelector } from "Reducers/testMode";
import { useAppSelector } from "Store/hooks";

import MainMobileMenu from "../MainMobileMenu";

import UserMenuDropdown from "./desktop/UserMenuDropdown";

import type { Me, Organization } from "@packages/client";

interface Props {
  user: Me;
  organizations?: Record<string, Organization | undefined>;
  accountUrl: string;
  organizationId?: string;
  breadcrumbs?: React.ReactNode;
}

const Menu = ({ user, accountUrl }: Props) => {
  const match = useMediaQuery("(max-width: 764px)");

  const isTestModeActive = useSelector(isActiveSelector);

  const profile = useAppSelector(selectUserProfileByMeUsername);
  return (
    <>
      {isTestModeActive && <MenuActiveTag>Test mode</MenuActiveTag>}
      {match ? (
        <MainMobileMenu profile={profile} user={user} accountUrl={accountUrl} />
      ) : (
        <UserMenuDropdown
          profile={profile}
          user={user}
          accountUrl={accountUrl}
        />
      )}
    </>
  );
};

export default Menu;
