import React from "react";

// eslint-disable-next-line no-restricted-imports
import { Tooltip } from "@packages/ds";

import * as analytics from "Libs/analytics";

interface TrackedRootProps extends React.ComponentProps<typeof Tooltip.Root> {
  analyticId?: string;
}

const TrackedRoot = ({
  analyticId,
  onOpenChange,
  ...props
}: TrackedRootProps) => {
  const handleOpenChange = (open: boolean) => {
    if (open && analyticId) {
      analytics.trackEventHeap(
        analytics.createUIAnalyticsEvent.tooltip.opened(analyticId)
      );
    }
    onOpenChange?.(open);
  };

  return <Tooltip.Root {...props} onOpenChange={handleOpenChange} />;
};

export default TrackedRoot;
