import { Typography } from "@packages/ds";
import styled from "styled-components";

export const Description = styled(Typography.BodyText).attrs(() => ({
  weight: "normal",
  size: "small"
}))`
  margin-top: 8px;
`;

export default Description;
