import React, { useEffect, useState } from "react";

import { Typography } from "@packages/ds";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  matchPath,
  useMatch,
  useLocation,
  useNavigate
} from "react-router-dom";

import Button, { ButtonWrapper, Link } from "ds/Button";
import Modal from "ds/Modal";
import { useLocalForage } from "Hooks/useLocalForage";
import BillingIcon from "Icons/BillingIcon";
import { meSelector } from "Reducers/app/selectors";
import { organizationProjectsLoadingSelector } from "Reducers/editingMember/organizationProjects/organizationProjects.selectors";
import getOrganizationProjects from "Reducers/editingMember/organizationProjects/thunks/getOrganizationProjects";
import { getFeatureFlag } from "Reducers/featureFlags";
import {
  selectFeatureOrganizationsEnabled,
  selectFeatureBillingEnabled,
  selectSubscribeFlowEnabled,
  selectReactivateTrialEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { useTrialInfo } from "Reducers/organization/hooks/useTrialnfo";
import { projectListSelector } from "Reducers/project/project";
import { currentTestTrialSelector } from "Reducers/testMode";
import BannerContainer from "src/organization/common/components/sellableBanners/BannerContainer";
import useOrganizationFromURL from "src/organization/pages/settings/pages/members/hooks/useOrganizationFromURL";
import { useAppSelector, useAppDispatch } from "Store/hooks";

import * as S from "./Trial.styles";

const Trial = ({ organizationId }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const currentPath = useLocation().pathname;
  const [customBannerMessage, setCustomBannerMessage] = useState(undefined);

  // Check our current path to see if it's a page we'll possibly need to show
  // a "Trial expired" modal on. Users should have access to their profile,
  // support, and billing/subscription pages
  // Users also need access to their project lists, but we need to make sure
  // not to include any descendant pages unless they match our previous paths

  const isHomePage = matchPath("/", currentPath);
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const {
    value: isClosed,
    setValue: setIsClosed,
    isLoading
  } = useLocalForage("trial_banner_container_closed", false);
  const currentTestTrial = useSelector(currentTestTrialSelector);
  const organizationEnabled = useAppSelector(selectFeatureOrganizationsEnabled);
  const billingEnabled = useAppSelector(selectFeatureBillingEnabled);
  const subscribeCtaEnabled = useAppSelector(selectSubscribeFlowEnabled);
  const organization = useOrganizationFromURL(organizationId);
  const canUserAddPaymentMethod = organization?.hasLink("payment-source");

  const {
    isTrial,
    isSuspended,
    config,
    trialDays,
    shouldShowModalInProjectOverview
  } = useTrialInfo({
    showCta: subscribeCtaEnabled && organization?.hasLink("payment-source")
  });
  const createProjectPagesList = [
    {
      pathPattern: "/:organizationId/create-project/:pattern",
      label: "Create project flow"
    },
    {
      pathPattern: "/:organizationId/create-project",
      label: "Create project first page"
    }
  ];

  const createProjectPageMatch = createProjectPagesList.find(
    ({ pathPattern }) => matchPath(pathPattern, currentPath)
  );

  const me = useAppSelector(meSelector);
  const projectsList = useSelector(projectListSelector)[organizationId] || {};

  const projects = Object.keys(projectsList);

  const cancellableSubdirectories = [
    "details",
    "verify",
    "migrate",
    "kickstart",
    "repository",
    "create-project"
  ];

  const isProjectCreationFlow = cancellableSubdirectories.some(subdirectory =>
    currentPath.includes(subdirectory)
  );

  const projectsIsLoading = useSelector(organizationProjectsLoadingSelector);
  const isReactivateTrialEnabled = useAppSelector(selectReactivateTrialEnabled);
  const projectsPageMatch = useMatch("/:organizationId");

  const hasProjects = projects?.length > 0;
  const projectsPageMsg = "create_project_page";

  useEffect(() => {
    if (organization) dispatch(getOrganizationProjects(organization.id));
  }, [dispatch, organization]);

  useEffect(() => {
    const shouldShowBanner =
      isClosed && !hasProjects && (isProjectCreationFlow || projectsPageMatch);
    setIsBannerOpen(!shouldShowBanner);
  }, [isClosed, projects, isProjectCreationFlow, setIsBannerOpen]);

  const hasCustomBannerMessage = getFeatureFlag("ENABLE_CUSTOM_BANNER_MESSAGE");

  useEffect(() => {
    if (
      hasCustomBannerMessage &&
      trialDays > 0 &&
      (createProjectPageMatch ||
        (!projectsIsLoading && projectsPageMatch && !hasProjects))
    ) {
      setCustomBannerMessage(projectsPageMsg);
    } else {
      setCustomBannerMessage(undefined);
    }
  }, [
    createProjectPageMatch,
    projectsPageMatch,
    hasProjects,
    projectsIsLoading,
    setCustomBannerMessage,
    projectsPageMsg
  ]);

  if (
    !billingEnabled &&
    !currentTestTrial &&
    (isLoading ||
      (Array.isArray(isClosed) && isClosed?.includes(config.priority)))
  )
    return null;

  if (isHomePage) return null;

  if ((!config.msg && !customBannerMessage) || !isTrial) return null;

  let billingUrl = `/billing`;
  if (organizationEnabled) {
    billingUrl = `/${organizationId}/-/billing`;
  }
  if (subscribeCtaEnabled) {
    billingUrl = `/${organizationId}/-/subscribe`;
  }

  function getBack() {
    if (organizationId) {
      navigate(`/${organizationId}`);
    } else {
      navigate("/");
    }
  }
  const shouldShowCloseButton = trialDays > 0;

  const onOpenChange = async () => {
    await setIsClosed(true);
    setIsBannerOpen(false);
  };

  const reactivateTrialRedirectParams = new URLSearchParams({
    subject: intl.formatMessage({
      id: "banner.reactivate.subject"
    }),
    description: intl.formatMessage({
      id: "banner.reactivate.description"
    }),
    priority: "low",
    category: "general_question",
    isGeneral: "true"
  });

  return (
    <>
      <BannerContainer
        isOpen={isBannerOpen}
        onOpenChange={onOpenChange}
        closeable={shouldShowCloseButton}
        size="small"
      >
        <S.BannerWrapper>
          <FormattedMessage
            id={`banner.trial.${customBannerMessage || config.msg}`}
            values={{
              daysRemaining: `${trialDays}`,
              linkBilling: txt =>
                canUserAddPaymentMethod ? (
                  <Link to={billingUrl}>{txt}</Link>
                ) : (
                  <></>
                ),
              billingIcon: () => (
                <S.BillingIconWrapper>
                  <BillingIcon />
                </S.BillingIconWrapper>
              ),
              learnMore: txt => (
                <S.LearnMoreLink
                  variant="link"
                  analyticId="links.faq"
                  href={intl.formatMessage({
                    id: "links.faq",
                    defaultMessage: "https://upsun.com/faq/"
                  })}
                >
                  {txt}
                </S.LearnMoreLink>
              )
            }}
          />
          {canUserAddPaymentMethod && !customBannerMessage ? (
            <>
              <S.SubscribeButton to={billingUrl} analyticId="banner.subscribe">
                {intl.formatMessage({ id: "banner.subscribe" })}
              </S.SubscribeButton>
              {isSuspended && isReactivateTrialEnabled && (
                <S.ReactivateButton
                  variant="outline"
                  to={`/-/users/${
                    me?.username
                  }/tickets/open?${reactivateTrialRedirectParams.toString()}`}
                >
                  {intl.formatMessage({ id: "banner.reactivate" })}
                </S.ReactivateButton>
              )}
            </>
          ) : (
            <></>
          )}
        </S.BannerWrapper>
      </BannerContainer>

      {shouldShowModalInProjectOverview && (
        <Modal
          isOpen={true}
          onOpenChange={() => {}}
          handleClose={() => getBack()}
        >
          <Typography.Title tag="h3">
            <FormattedMessage id="trial.modal.subscribe.title" />
          </Typography.Title>
          <Typography.BodyText>
            <FormattedMessage
              id={`trial.modal.subscribe.${
                subscribeCtaEnabled && organization?.hasLink("payment-source")
                  ? "body_cta"
                  : "body"
              }`}
            />
          </Typography.BodyText>
          <ButtonWrapper spacing="modal" justifyContent="end">
            {subscribeCtaEnabled && organization?.hasLink("payment-source") ? (
              <>
                <Button
                  variant="secondary"
                  onClick={() => navigate(-1)}
                  analyticId="cancel"
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <Button href={billingUrl} analyticId="subscribe">
                  <FormattedMessage id="subscribe" />
                </Button>
              </>
            ) : (
              <Button onClick={() => navigate(-1)} analyticId="okay">
                <FormattedMessage id="okay" />
              </Button>
            )}
          </ButtonWrapper>
        </Modal>
      )}
    </>
  );
};

Trial.propTypes = {
  organizationId: PropTypes.string
};

export default Trial;
