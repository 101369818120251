import { SettingsIcon as BaseSettingsIcon } from "@packages/ds";
import styled from "styled-components";

import { Button as ButtonDs } from "ds/Button";

export const ActionButtons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  z-index: 1;
  justify-content: flex-start;
  gap: 8px;
`;

export const ButtonIcon = styled(ButtonDs)`
  margin-left: 12px;
  width: 32px;
  background-color: var(--mode-bg-none-default);
  color: var(--mode-vector-neutral-default);

  &:hover {
    background-color: var(--mode-bg-neutral-inverse-weaker);
  }
`;

export const SettingsIcon = styled(BaseSettingsIcon)`
  color: var(--mode-vector-neutral-default);
`;
