import { AnalyticsEvent } from "./types";

export const createUIAnalyticsEvent = {
  dialog: {
    opened: (analyticId?: string): AnalyticsEvent => ({
      name: "info dialog opened",
      payload: { value: analyticId }
    })
  },
  tooltip: {
    opened: (analyticId: string): AnalyticsEvent => ({
      name: "tooltip opened",
      payload: { value: analyticId }
    })
  },
  radio: {
    clicked: (analyticId: string): AnalyticsEvent => ({
      name: "radio clicked",
      payload: { value: analyticId }
    })
  },
  checkbox: {
    clicked: (
      check_id: string,
      value: boolean,
      analyticId: string
    ): AnalyticsEvent => ({
      name: "checkbox clicked",
      payload: { check_id, value, analyticId }
    })
  },
  select: {
    changed: (analyticId: string, selectedValue: string): AnalyticsEvent => ({
      name: "select value changed",
      payload: { value: analyticId, selectedValue }
    })
  },
  dropdown: {
    opened: (analyticId: string): AnalyticsEvent => ({
      name: "dropdown open changed",
      payload: { value: analyticId }
    }),
    itemSelected: (
      analyticId: string,
      type: string,
      checked?: boolean | "indeterminate"
    ): AnalyticsEvent => ({
      name: `dropdown item type ${type} selected`,
      payload: { value: analyticId, checked }
    })
  },
  tab: {
    activated: (analyticId: string): AnalyticsEvent => ({
      name: "tab active",
      payload: { value: analyticId }
    })
  }
};
