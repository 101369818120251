import React, { useState, memo, useCallback, useEffect } from "react";

import { Typography } from "@packages/ds";
import { FormattedMessage, useIntl } from "react-intl";

import * as Button from "ds/Button";
import Modal from "ds/Modal";
import Tag from "ds/Tag";
import useDecodedParams from "Hooks/useDecodedParams";
import { useTrialInfo } from "Reducers/organization/hooks/useTrialnfo";
import { useLoggedInMember } from "Reducers/organization/loggedInMember/hooks/useLoggedInMember";
import Permissions from "src/organization/pages/settings/pages/components/Permissions";
import useInvitations from "src/organization/pages/settings/pages/members/hooks/useInvitations";

import InvitationEmailBox from "./EmailBox";
import * as S from "./InviteModal.styles";

import type { OrganizationMemberPermissions } from "Api/organizations/member/Member.types";

export interface InviteModalProps {
  organizationId: string;
  onOpenChange: () => void;
  organizationCapabilities: string[];
}

const renderHiddenElement = (element: string) => (
  <div key={element}>{element}</div>
);

const InviteModal = ({
  organizationId,
  organizationCapabilities,
  onOpenChange
}: InviteModalProps) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState<Array<string>>([]);
  const [permissions, setPermissions] = useState<
    Array<OrganizationMemberPermissions>
  >([]);
  const { invite } = useInvitations(organizationId);
  const { load: loadLoggedUser } = useLoggedInMember(organizationId);
  const organization = useDecodedParams<{ organizationId: string }>();

  const { isTrial } = useTrialInfo();

  const onCancel = useCallback(() => {
    setEmails([]);
    setPermissions([]);
    onOpenChange();
  }, [onOpenChange]);

  const onInvite = async () => {
    setIsLoading(true);
    await invite(emails, permissions);
    setIsLoading(false);
    onCancel();
  };

  useEffect(() => {
    loadLoggedUser();
  }, [loadLoggedUser]);

  const renderElement = useCallback(
    (element: string) => (
      <InvitationEmailBox
        key={element}
        element={element}
        onClose={id =>
          setEmails(emails => emails.filter(email => email !== id))
        }
      />
    ),
    []
  );
  return (
    <Modal isOpen={true} onOpenChange={onCancel} size="medium">
      <S.TitleTagWrapper>
        <Typography.Title tag="h3">
          <FormattedMessage id="organizations.invite_user(s)" />
        </Typography.Title>{" "}
        {isTrial && (
          <Tag variant="rose">
            {intl.formatMessage({
              id: `organizations.free_during_trial`
            })}
          </Tag>
        )}
      </S.TitleTagWrapper>
      <Typography.BodyText>
        <FormattedMessage id="add_user_organization" />
      </Typography.BodyText>

      <S.Input
        data-heap-redact-text
        value={emails}
        onChange={value => setEmails(value)}
        placeholder={intl.formatMessage({ id: "members_email_address" })}
      />

      <S.PermissionsLabel>
        <Typography.BodyText as="div" size="small" weight="bold">
          <FormattedMessage id="organizations.permissions" />
        </Typography.BodyText>
        {/* test also with an org without members add on */}
        {organizationCapabilities.includes("member_permissions") ? (
          <Typography.BodyText size="small">
            <FormattedMessage id="add_on_invite_modal_note.with_addon" />
            <Button.Link
              analyticId="learnmore"
              href={intl.formatMessage({
                id: "links.documentation.organizations"
              })}
            >
              <FormattedMessage id="learnmore" />
            </Button.Link>
          </Typography.BodyText>
        ) : (
          <Typography.BodyText size="small">
            <FormattedMessage id="add_on_invite_modal_note" />
            <Button.Link
              to={`/${organization?.organizationId}/-/billing`}
              analyticId="upgrade_to_standard_add_on"
            >
              <FormattedMessage id="upgrade_to_standard_add_on" />
            </Button.Link>
          </Typography.BodyText>
        )}
      </S.PermissionsLabel>

      <S.PermissionsWrapper>
        <Permissions
          value={permissions}
          onChange={permissions => setPermissions(permissions)}
          organizationId={organizationId}
          organizationCapabilities={organizationCapabilities}
        />
      </S.PermissionsWrapper>

      {!!emails.length && (
        <>
          <S.OverflowListActions>
            <Typography.Label>{emails.length} Users</Typography.Label>
            <Button.Button
              variant="link"
              onClick={() => setEmails([])}
              analyticId="clear_all"
            >
              <FormattedMessage id="clear_all" />
            </Button.Button>
          </S.OverflowListActions>

          <S.EmailList
            data-heap-redact-text
            elements={emails}
            overflowDirection="vertical"
            renderElement={renderElement}
            renderHiddenElement={renderHiddenElement}
          />
        </>
      )}

      <S.TrialNote>
        <Typography.BodyText size="small">
          <FormattedMessage
            id={
              isTrial ? "organizations.permissions.note" : "price_increase_note"
            }
          />
          <Button.Link
            href={intl.formatMessage({ id: "links.pricing" })}
            analyticId="pricing.page"
          >
            <FormattedMessage id="pricing.page" />.
          </Button.Link>
        </Typography.BodyText>
      </S.TrialNote>
      <Button.ButtonWrapper spacing="modal" justifyContent="end">
        <Button.Button
          variant="secondary"
          onClick={onCancel}
          analyticId="cancel"
        >
          <FormattedMessage id="cancel" />
        </Button.Button>
        <Button.Button
          disabled={isLoading || !emails.length}
          onClick={onInvite}
          analyticId="invite"
        >
          <FormattedMessage id="invite" />
        </Button.Button>
      </Button.ButtonWrapper>
    </Modal>
  );
};

export default memo(InviteModal);
