import { Typography } from "@packages/ds";
import styled from "styled-components";

import InputDs from "ds/Input";

export const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 24px;
  background-color: var(--section-snow-background-color, var(--snow));
  border-radius: 2px;
  max-width: 50%;
`;

export const AppItem = styled.div`
  span + button {
    margin: 10px;
  }
`;

export const Input = styled(InputDs)`
  margin-bottom: 16px;
`;

export const Title = styled(Typography.Title)`
  margin-bottom: 32px;
`;
