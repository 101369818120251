// eslint-disable-next-line no-restricted-imports
import { Tooltip as BaseTooltip } from "@packages/ds";

import TrackedRoot from "./TrackedRoot";
import TrackedTooltip from "./TrackedTooltip";

export const Tooltip = Object.assign(TrackedTooltip, {
  Root: TrackedRoot,
  Provider: BaseTooltip.Provider,
  Trigger: BaseTooltip.Trigger,
  Content: BaseTooltip.Content,
  Arrow: BaseTooltip.Arrow
});
