import React, { memo } from "react";

import { Typography } from "@packages/ds";

import { CloseButton } from "ds/Button";
import { Box } from "ds/OverflowList";

export interface EmailBoxProps {
  element: string;
  onClose: (element: string) => void;
}

const EmailBox = ({ element, onClose }: EmailBoxProps) => (
  <Box>
    <Typography.Label textStyle="extraSmall">{element}</Typography.Label>
    <CloseButton onClick={() => onClose(element)} />
  </Box>
);

export default memo(EmailBox);
