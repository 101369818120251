import React, { useEffect, useMemo, useState } from "react";

import { CopyIcon, SuccessIcon, MoreIcon } from "@packages/ds";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import * as Dropdown from "ds/Dropdown";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import getUrl from "Libs/getUrl";
import {
  copyToClipboard,
  getSubscriptionEditUrl,
  splitStringByChar
} from "Libs/utils";
import {
  selectExternalUrlEnabled,
  selectFeatureEditPlanEnabled,
  selectFeatureProjectSetupWizardEnabled,
  selectFeatureUsageBasedBillingEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { organizationSelector } from "Reducers/organization";
import { selectProjectBuildResourcesEnabled } from "Reducers/project/capabilities";
import { RootState } from "Store/configureStore";
import { useAppSelector } from "Store/hooks";

import * as S from "./ProjectMoreLinks.styles";

import type { Project, Subscription } from "@packages/client";

export interface ProjectMoreLinksProps {
  project?: Project;
  subscription?: Subscription;
  toggleProjectWizard?: () => void;
  isNav?: boolean;
  iconColor?: string;
  from?: string;
  viewLink?: boolean;
}

const ProjectMoreLinks = ({
  iconColor = "white",
  from,
  project,
  subscription,
  toggleProjectWizard,
  isNav = false,
  viewLink
}: ProjectMoreLinksProps) => {
  const location = useLocation();
  const { organizationId } = useDecodedParams();

  const [isCopied, setIsCopied] = useState(false);
  const editPlanEnabled = useAppSelector(selectFeatureEditPlanEnabled);
  const projectSetupWizardEnabled = useAppSelector(
    selectFeatureProjectSetupWizardEnabled
  );

  const externalUrlEnabled = useAppSelector(selectExternalUrlEnabled);

  const intl = useIntl();

  const subscriptionStatus = useSelector(
    ({ subscription }) => subscription?.status
  );

  const organization = useSelector((state: RootState) =>
    organizationSelector(state, {
      organizationId: project?.organization_id || subscription?.owner
    })
  );

  const usageBasedBillingEnabled = useSelector(
    selectFeatureUsageBasedBillingEnabled
  );
  const areBuildResourcesEnabled = useSelectorWithUrlParams(
    selectProjectBuildResourcesEnabled
  );

  useEffect(() => {
    let isCanceled = false;
    let timer;
    if (isCopied) {
      timer = setTimeout(() => !isCanceled && setIsCopied(false), 500);
    }
    return () => {
      isCanceled = true;
      clearTimeout(timer);
    };
  }, [isCopied]);

  useEffect(() => {
    if (subscriptionStatus === "deleted")
      window.location.replace(window.location.origin);
  }, [subscriptionStatus]);

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription, organization }),
    [project, subscription, organization]
  );

  const copyId = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent?.stopPropagation();
    setIsCopied(true);
    copyToClipboard(project?.id || subscription?.project_id || "");
  };

  if ((!project || !subscription) && !subscriptionEditUrl) {
    return null;
  }

  const { pathname } = splitStringByChar(subscriptionEditUrl?.url || "", "#");

  const internalProps = {
    to: pathname,
    state: {
      from,
      url: location.pathname
    }
  };
  const externalProps = {
    href: subscriptionEditUrl?.url
  };
  const linkProps = externalUrlEnabled ? externalProps : internalProps;
  const projectId = project?.id || subscription?.project_id || "";

  const showSubLinkEdit =
    editPlanEnabled && !usageBasedBillingEnabled && subscriptionEditUrl;

  return (
    <Dropdown.Root>
      <S.DropdownTrigger
        $isNav={isNav}
        onClick={e => e?.preventDefault()}
        data-testid="trigger-project-more"
      >
        <MoreIcon color={iconColor} />
      </S.DropdownTrigger>
      <Dropdown.Content align="end">
        <Dropdown.Label>
          {intl.formatMessage({
            id: "projects.toggle.sort_by.project_id",
            defaultMessage: "Project ID"
          })}
        </Dropdown.Label>

        <Dropdown.Item
          analyticId="project.morelinks.id"
          onClick={copyId}
          data-testid="copy-project-id"
        >
          {projectId}
          {isCopied ? (
            <SuccessIcon aria-hidden="true" />
          ) : (
            <CopyIcon aria-hidden="true" />
          )}
        </Dropdown.Item>
        <S.DropdownSeparator />

        <Dropdown.Label>
          {intl.formatMessage({
            id: "project.state.not_live",
            defaultMessage: "Go live"
          })}
        </Dropdown.Label>

        <Dropdown.LinkItem
          analyticId="project.morelinks.add_domain"
          to={getUrl({
            key: "organization.project.settings.domains",
            props: {
              organizationId: organizationId || "",
              projectId
            }
          })}
        >
          {intl.formatMessage({
            id: "settings.domains.add.button",
            defaultMessage: "Add domain"
          })}
        </Dropdown.LinkItem>

        {toggleProjectWizard && projectSetupWizardEnabled && (
          <Dropdown.Item
            analyticId="project.morelinks.wizard"
            onClick={toggleProjectWizard}
            data-testid="toggle-wizard"
          >
            {intl.formatMessage({
              id: "project.morelinks.wizard",
              defaultMessage: "Setup wizard"
            })}
          </Dropdown.Item>
        )}

        <S.DropdownSeparator />

        <Dropdown.LinkItem
          analyticId="project.morelinks.project_access"
          to={getUrl({
            key: "organization.project.settings.access",
            props: {
              organizationId: organizationId || "",
              projectId
            }
          })}
        >
          {intl.formatMessage({
            id: "project_access",
            defaultMessage: "Project access"
          })}
        </Dropdown.LinkItem>

        <Dropdown.LinkItem
          analyticId="project.morelinks.project_settings"
          to={getUrl({
            key: "organization.project.settings",
            props: {
              organizationId: organizationId || "",
              projectId
            }
          })}
        >
          {intl.formatMessage({
            id: "project_setting",
            defaultMessage: "Project settings"
          })}
        </Dropdown.LinkItem>

        {showSubLinkEdit && (
          <>
            {subscriptionEditUrl?.external ? (
              <Dropdown.LinkItem
                data-testid="sub-link-edit"
                analyticId="project.morelinks.edit"
                href={subscriptionEditUrl?.url}
              >
                {intl.formatMessage({
                  id: "project.morelinks.edit",
                  defaultMessage: "Edit plan"
                })}
              </Dropdown.LinkItem>
            ) : (
              <Dropdown.LinkItem
                data-testid="sub-link-edit"
                {...linkProps}
                analyticId="project.morelinks.edit"
              >
                {intl.formatMessage({
                  id: "project.morelinks.edit",
                  defaultMessage: "Edit plan"
                })}
              </Dropdown.LinkItem>
            )}
          </>
        )}
        {viewLink && (
          <Dropdown.LinkItem
            analyticId="project.morelinks.view"
            to={{
              pathname: getUrl({
                key: "organization.project",
                props: {
                  organizationId:
                    organization?.name || subscription?.owner || "",
                  projectId
                }
              }),
              state: { from }
            }}
          >
            {intl.formatMessage({
              id: "project.morelinks.view",
              defaultMessage: "View project"
            })}
          </Dropdown.LinkItem>
        )}

        {areBuildResourcesEnabled && (
          <Dropdown.LinkItem
            analyticId="project.morelinks.configure_resources"
            to={getUrl({
              key: "organization.project.usageBasedBilling",
              props: {
                organizationId: organizationId || "",
                projectId,
                subscriptionId: subscription?.id || ""
              }
            })}
          >
            {intl.formatMessage({
              id: "organization.billing.breakdown.project_billing",
              defaultMessage: "Project Billing"
            })}
          </Dropdown.LinkItem>
        )}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export default ProjectMoreLinks;
