import React from "react";

import { InfoSmIcon as Info } from "@packages/ds";
import { FormattedMessage, useIntl } from "react-intl";

import { Link } from "ds/Button";
import * as Dialog from "ds/Dialog";
import { Tooltip } from "ds/Tooltip";
import { hasSafeRole } from "Libs/utils";
import { selectCurrentUserRoles } from "Reducers/app/selectors";
import { getFeatureFlagFromEnvironment } from "Reducers/featureFlags/environment";
import { permissionsKeys } from "Reducers/organization/invitations/permissions";
import { useAndLoadLoggedInMember } from "Reducers/organization/loggedInMember/hooks/useAndLoadLoggedInMember";
import { useAppSelector } from "Store/hooks";

import * as S from "./Permissions.styles";

import type { OrganizationMemberPermissions } from "Api/organizations/member/Member.types";

export interface PermissionsProps {
  organizationId: string;
  onChange: (permission: Array<OrganizationMemberPermissions>) => void;
  value: Array<OrganizationMemberPermissions>;
  organizationCapabilities: string[];
  disableAllPermissions?: boolean;
}

export const Permissions = (props: PermissionsProps) => {
  const intl = useIntl();
  const memberRoles = useAppSelector(selectCurrentUserRoles);
  const loggedInUserDetails = useAndLoadLoggedInMember(props.organizationId);

  const selectPermissions = (permission: OrganizationMemberPermissions) => {
    // Remove
    if (props.value.includes(permission)) {
      // If the user had the admin permission, we remove all the permissions
      // since they are suppoed to have them all
      if (permission === "admin") {
        props.onChange([]);
      } else {
        props.onChange(props.value.filter(p => p !== permission));
      }
      // Add
    } else {
      const permissions = [permission];
      if (["billing", "plans"].includes(permission)) {
        permissions.push("projects:list");
      }

      // Avoid stacking admin permissions with the other permissions. The api
      // will clean this up but is better to not send garbage if we can avoid it
      if (permission === "admin") {
        props.onChange(permissions);
      } else {
        // Avoids sending any duplicates
        props.onChange(Array.from(new Set([...props.value, ...permissions])));
      }
    }
  };

  // The user is already an admin or the form has been modified to make the user an admin
  // Checking the loggedInUserDetails will be a mistake since the form might not have been
  // submitted yet
  const isAdmin = props.value.includes("admin");

  return (
    <>
      {permissionsKeys.map(permission => {
        const userCantAssignPermission =
          !loggedInUserDetails?.member.permissions?.includes(permission) &&
          !loggedInUserDetails?.member.owner &&
          !loggedInUserDetails?.member.permissions?.includes("admin") &&
          !hasSafeRole(memberRoles);

        const isProjectListDisabled =
          permission === "projects:list" &&
          (props.value.includes("plans") || props.value.includes("billing"));

        let checkbox = (
          <S.Checkbox
            forId={permission}
            key={permission}
            label={<FormattedMessage id={`access.${permission}`} />}
            id={permission}
            checked={
              isAdmin ||
              props.value.includes(permission) ||
              !props?.organizationCapabilities?.includes("member_permissions")
            }
            disabled={
              props.disableAllPermissions ||
              (isAdmin && permission !== "admin") ||
              userCantAssignPermission ||
              isProjectListDisabled ||
              !props?.organizationCapabilities?.includes(
                "member_permissions"
              ) ||
              props.disableAllPermissions
            }
            onCheckedChanged={() => selectPermissions(permission)}
          />
        );

        if (
          userCantAssignPermission &&
          props?.organizationCapabilities?.includes("member_permissions")
        ) {
          checkbox = (
            <S.TooltipWrapper key={permission}>
              <Tooltip
                analyticId="organization.members.caneditassigned"
                tooltip={
                  <FormattedMessage id="organization.members.caneditassigned" />
                }
              >
                {checkbox}
              </Tooltip>
            </S.TooltipWrapper>
          );
        }

        if (permission === "projects:list") {
          const translationKey = getFeatureFlagFromEnvironment(
            "ENABLE_ORGANIZATION_PLANS_PERMISSION"
          )
            ? "organization.permissions.projects:list.details"
            : "organization.permissions.projects:list.details.noplans";
          return (
            <S.PermissionDialog key={permission}>
              {checkbox}
              <Dialog.Root>
                <Dialog.Trigger analyticId={translationKey}>
                  <S.Icon>
                    <Info />
                  </S.Icon>
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Content>
                    <Dialog.Title>
                      <FormattedMessage id="list.projects.permission" />
                    </Dialog.Title>

                    <Dialog.Description>
                      <FormattedMessage id={translationKey} />
                    </Dialog.Description>
                    <Link
                      analyticId="learnmore"
                      href={intl.formatMessage({
                        id: "links.documentation.organizations.manage-your-organization-users"
                      })}
                    >
                      <FormattedMessage id="learnmore" />
                    </Link>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </S.PermissionDialog>
          );
        }

        return checkbox;
      })}
    </>
  );
};
