import React from "react";

import { Typography } from "@packages/ds";
import { useIntl } from "react-intl";

import { Button } from "ds/Button";

import * as S from "../SourceOperation.style";

type UpgradeSourceOpsProps = {
  onClose: () => void;
  isRuntime?: boolean;
};
export const UpgradeSourceOps = ({
  onClose,
  isRuntime
}: UpgradeSourceOpsProps) => {
  const formatMessage = useIntl().formatMessage;
  return (
    <S.SourceOperationContainer>
      <Typography.Title tag="h3">
        {isRuntime
          ? formatMessage({ id: "source_ops.upgrade.runtime.title" })
          : formatMessage({ id: "source_ops.upgrade.title" })}
      </Typography.Title>
      <S.OperationDescription className="operation-notice">
        {formatMessage(
          {
            id: isRuntime
              ? "source_ops.upgrade.runtime.message"
              : "source_ops.upgrade.message"
          },
          {
            br: <br />
          }
        )}
      </S.OperationDescription>

      <S.SourceButtonWrapper>
        <Button
          variant="outline"
          analyticId="contact_sales"
          href={formatMessage({ id: "links.platform.contact" })}
        >
          {formatMessage({ id: "contact_sales" })}
        </Button>
        <Button variant="secondary" analyticId="cancel" onClick={onClose}>
          {formatMessage({ id: "cancel" })}
        </Button>
      </S.SourceButtonWrapper>
    </S.SourceOperationContainer>
  );
};

export default UpgradeSourceOps;
