import React from "react";

import PageLayout from "Components/PageLayout";
import NavBar from "Containers/NavBar";
import PluginApp from "src/organization/pages/project/app/Page";

import { Layout } from "./Routes.styles";

const Routes = () => {
  return (
    <>
      <NavBar />
      <PageLayout>
        <Layout>
          <PluginApp route="/" type="page" />
        </Layout>
      </PageLayout>
    </>
  );
};

export default Routes;
