import { Typography } from "@packages/ds";
import styled from "styled-components";

import { smallShadow } from "ds/Shadows";

export const Box = styled.div`
  background-color: var(--section-snow-background-color, var(--snow));
  min-width: fit-content;
  height: 20px;
  align-items: center;
  padding: 2px 2px 2px 0;
  display: flex;
  ${smallShadow}
  border-radius: 2px;
  transform: scale(1);

  ${Typography.Label} {
    margin: 0 8px;
  }
`;

export default Box;
