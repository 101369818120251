import { Components } from "./components";
import TextOverflow from "./TextOverflow";

export const Typography = {
  ...Components,
  TextOverflow
};

export type { BodyTextProps } from "./components/BodyText.styles";
export type { LabelProps } from "./components/Label.styles";
export type { TextOverflowProps } from "./TextOverflow";

export default Typography;
