import { Typography } from "@packages/ds";
import styled from "styled-components";

const Text = styled(Typography.Label).attrs(() => ({ as: "div" }))`
  display: flex;
  align-items: center;
  flex: 1;
  word-break: all;
  color: var(--theme-neutral-900);

  a {
    font-weight: inherit;
  }

  & > a {
    padding-left: 4px;
  }
`;

export default Text;
