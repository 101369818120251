import { Typography, type BodyTextProps } from "@packages/ds";
import styled from "styled-components";

const Error = styled(Typography.BodyText).attrs((props: BodyTextProps) => ({
  size: props.size || "small"
}))`
  display: block;
  margin-top: 4px;
  color: var(--mode-text-critical-default);
`;

export default Error;
