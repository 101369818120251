import React from "react";

// eslint-disable-next-line no-restricted-imports
import { Tooltip as BaseTooltip } from "@packages/ds";

import { trackEventHeap, createUIAnalyticsEvent } from "Libs/analytics";

import type { TooltipProps } from "@packages/ds";

interface TrackedTooltipProps extends TooltipProps {
  analyticId?: string;
}

const TrackedTooltip = ({
  analyticId,
  onOpenChange,
  ...props
}: TrackedTooltipProps) => {
  const handleOpenChange = (open: boolean) => {
    if (open && analyticId) {
      trackEventHeap(createUIAnalyticsEvent.tooltip.opened(analyticId));
    }
    onOpenChange?.(open);
  };

  return <BaseTooltip {...props} onOpenChange={handleOpenChange} />;
};

export default TrackedTooltip;
